import Vue from 'vue'
import App from './App.vue'
import '../src/assets/css/foot.css';
import '../src/assets/css/index.css';
import '../src/assets/css/index1.css';
import '../src/assets/css/main.css';
import '../src/assets/css/right.css';
import '../src/assets/css/style2.css';
import '../src/assets/css/swiper.css';
import router from './router'
import API from './api'
Vue.prototype.$api = API
// 将各模块api挂载在vue实例上
Vue.config.productionTip = false
import jquery from 'jquery';
Vue.prototype.jquery = $
console.log(process.env);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
