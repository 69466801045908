<template>
  <div class="rementj">
    <div class="title-sjsal mt30 mb20">
          <span>热门推荐</span>
          <!-- <div class="more"><a href="" target="_blank" class="a-hover">查看更多 <i class="iconfont icon-gengduo1"></i></a></div> -->
      </div>
      
      <div class="describe-video-study describe-page-index-module pdt20 clearfix">
          <div class="left fl">
              
              <a href="/yyanldetail/" target="_blank" class="img block zoomBox lazy">
                  <img src="../assets/static/upload/image/20221104/1667552091338998.png" class="lazy_img auto zoom" alt="家具家电招商加盟的条件有哪些？" title="家具家电招商加盟的条件有哪些？" width="100%">
              </a>
              <a :href="'/mtdddetail?id='+2" target="_blank" class="block text-black f18 ellipsis a-hover mt5">家具家电招商加盟的条件有哪些？</a><br><br>
              <a :href="'/mtdddetail?id='+2" target="_blank" class="block text-color9 ellipsis f12 mt5">时间：2022-11-04 <span class="ml40">浏览数：32次</span></a>
              <a :href="'/mtdddetail?id='+2" target="_blank" class="block ellipsis2 f12 text-sub detail mt14 a-hover">俗话说得好“男怕入错行，女怕嫁错郎”，想要创业致富，项目选择是关键。同骋工匠，是一个全国连锁的综合型师傅用工平台，深受广大消费者和加盟商的喜爱，具有非常好的发展前景，这也让许多投资者纷纷跃跃欲试想要加盟。...</a> 
          </div>
          <div class="right fr">
              
              <a :href="'/mtdddetail?id='+item.id" target="_blank" class="block new-con mb17 clearfix" v-for="item,index in list" :key="index">
                  <div class="time pr fl text-white">
                      <div class="month pa f36">10</div>
                      <div class="day pa f18">/28</div>
                  </div>
                  <div class="fr mr10 detail">
                      <div class="f18 text-black ellipsis a-hover pdt10 pdb5">{{ item.title }}</div>
                      <div class="ellipsis2 text-sub f12 a-hover con">{{ item.introduce }}</div>
                  </div>
              </a>
              
            
              
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'rementj',
  props: {
    msg: String
  },
  data() {
        return {list:[]}
    },
    mounted() {
        this.$api.common.newshot().then(res => {
            console.log(res)
            this.list = res.data.result

        }).catch(res => {

        })
    }
}
</script>


