<template>
    <div class="yingyonganli">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
        <div class="header-middle">
            <div class="con">
                <div class="yz-row header_inner">
                    <div class="yz-column whb-visible-lg">
                        <div class="header-logo">
                            <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid" 
							src="../assets/images/logos.png" alt="同骋天下"></a>
                        </div>

                    </div>
                    <div class="yz-column zhong whb-visible-lg">
                        <div class="con text-right">
                            <ul class="menu">
                                <li class="position-relative"><a href="/"><span>首页</span></a></li>
                                
                                <li class="position-relative">
                                    <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a> 
                                        <a href="/cpzx/" class="a-hover hlist">产品介绍</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/video/" rel="/video/"><span>服务视频</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a> 
                                        <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                        <div class="yousan tianmao">
                            <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                        </div>
                        <div class="wx yousan" id="wechat">
                            <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/weixin.jpg" />
                        </div>
                        <div class="phone yousan wx" id="wechat">
                           <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="width: 100%;position: relative;">
    <img src="../assets/images/case.jpg" alt="" width="100%">
    <div class="case-ban">
        <ul>
            
            <li>
                <a href="javascript:;" title="装饰风格">
                    <h3>装饰风格</h3>
                    <p>Decorative style</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="房屋装修">
                    <h3>房屋装修</h3>
                    <p>House decoration</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="灯饰安装">
                    <h3>灯饰安装</h3>
                    <p>Lighting installation</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="油烟机">
                    <h3>油烟机</h3>
                    <p>Lampblack machine</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="投影仪">
                    <h3>投影仪</h3>
                    <p>Projector</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="家具养护">
                    <h3>家具养护</h3>
                    <p>Furniture</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="浴霸安装">
                    <h3>浴霸安装</h3>
                    <p>Yuba installation</p>
                </a>
            </li>
            
            <li>
                <a href="javascript:;" title="家政服务">
                    <h3>家政服务</h3>
                    <p>Housekeeping</p>
                </a>
            </li>
            
        </ul>
    </div>
</div>
<div class="container mianbao">
    <div class="anli_nav">
        当前位置 ：<a href="/">首页</a>>><a href="/yingyonganli/">工程案例</a>
    </div>
    <div class="clearfix" id="anliyun">
    </div>
    <div class="cl"></div>
</div>
<div class="i-main produce-main pb70">

    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Decorative style</p>
                <h3>装饰风格</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    
                    <li v-for="item,index in list.gwal" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                    
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>House decoration</p>
                <h3>房屋装修</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    
                    <li v-for="item,index in list.fdc" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Lighting installation</p>
                <h3>灯饰安装</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    
                    <li v-for="item,index in list.shangye" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Lampblack machine</p>
                <h3>油烟机</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.jiudian" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Projector</p>
                <h3>投影仪</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.jy" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Furniture</p>
                <h3>家具养护</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.qiche" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Yuba installation</p>
                <h3>浴霸安装</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.xzl" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
    <div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Housekeeping</p>
                <h3>家政服务</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.jinrongchanye" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
	<div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Domestic outfit</p>
                <h3>家装</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.jz" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
	<div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Housekeeping</p>
                <h3>家政</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.jiazheng" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
	<div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>The installation</p>
                <h3>安装</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.az" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
	<div class="case-public sign" id="sign1">
        
        <div class="i-title-box">
            <div class="i-title">
                <p>Other</p>
                <h3>其它</h3>
            </div>
        </div>
        
        <div class="box container">
            <div class="container f-cb case-list4 list-case2">
                <ul style="width: 100%;">
                    <li v-for="item,index in list.qt" :key="index">
                        <img src="../assets/images/ph02.jpg" width="100%" height="320px" alt="">
                        <a :href="'/yyanldetail?id='+item.id" title="">
                            <img :src="item.image" width="91.6%" :alt="item.title" class="poa">
                            <p>{{ item.title }}</p>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <a href="javascript:;" class="more" id="more5" title="">查看更多</a>
        </div>
    </div>
</div>

<div style="clear: both;"></div>
        <footerbox></footerbox>
    </div>
</template>
<script>
 $(window).ready(function() {

$(window).scroll(function() {

    var pos = $(window).scrollTop();

    var bottomBox = $(".head-sub").find('.bottomBox');

    if (pos > 500) {

        $('.zyA_right').show();

    } else {

        $('.zyA_right').hide();

    }

    if (pos > 110) {

        bottomBox.addClass('fixedX');

    } else {

        bottomBox.removeClass('fixedX');

    }

});

$('.case-ban li').click(function() {

    var n = $(this).index();

    var t = $('.i-main .sign').eq(n).offset().top;

    $('body,html').animate({

        'scrollTop': t - 100

    });

})

$('.anli_pro .content a').wrap("<span></span>");

// $('.anli_pro .content b').hide();

var swiper = new Swiper('.swiper-container', {

    slidesPerView: 3,

    spaceBetween: 30,

    navigation: {

        nextEl: '.swiper-button-next',

        prevEl: '.swiper-button-prev',

    },



});

})
$(function() {

$(document).scroll(function() {

    var scroH = $(document).scrollTop(); //滚动高度



    if (scroH > 400) {

        $('#headerhome').css("position", "fixed");

    } else {

        $('#headerhome').css("position", "inherit");

    }

    if (scroH < 550) {
        $('.construction_right').css("position", "static");
    } else if (scroH >= 550) {
        $('.construction_right').css({
            "position": "sticky",
            "top": "15%",
            "left": "66%"
        });

    }


})

$(".menu li").mouseover(function() {

    $(this).css({

        "color": "#FF6602"

    }).siblings().css({

        "color": "#101920"

    });

})

$(".menu li").mouseout(function() {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
$('.domesticOutfit .doys span').click(function() {
        $('.domesticOutfit').hide();
    })

$("#footer .share a").hover(function(e) {
    $(this).find("figure").fadeToggle('fast');
});

var urlstr = location.href; //获取浏览器的url

var urlstatus = false; //标记

$("#headerhome .menu li>a").each(function() {

    //判断导航里面的rel和url地址是否相等

    if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

        $(this).find('.io').addClass('ms');

        $(this).css("color", "#FF6602");

        urlstatus = true;

    } else {

        $(this).find('.io').removeClass('ms');

        $(this).css("color", "#101920");

    }

});

//当前样式保持

if (!urlstatus) {

    $("#menu a").eq(0).addClass('cur');

}



$('b span:last').after('</br>');

})
$(function() {

$(".mobile").hover(function() {

    $(this).find(".mobilecode").css({

        "display": "block"

    })

}, function() {

    $(this).find(".mobilecode").css({

        "display": "none"

    })

})

$(".side ul li").hover(function() {

    $(this).find(".sidebox").stop().css({

        "opacity": "1",

        "filter": "Alpha(opacity=100)",

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "block"

    })



}, function() {

    $(this).find(".sidebox").stop().animate({

        "width": "54px"

    }, 200).css({

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "none"

    })

});

$(window).scroll(function() {

    if ($(window).scrollTop() > 100) {

        $("#back-to-top").fadeIn(300);

    } else {

        $("#back-to-top").fadeOut(300);

    }

});

});
import footerbox from '../components/footerbox.vue'
export default {
    name: 'yingyonganli',
    components: {
        footerbox
    },
    data() {
        return {list:{}}
    },
    mounted() {
        this.$api.common.engineering().then(res => {
            console.log(res)
            this.list = res.data.result

        }).catch(res => {

        })
    }


}
</script>
<style>
    .Footer_footer__3lJSd {
        margin-top: 60px;
    }
    
    .i-title p {
        font-family: 'beli2';
    }
</style>