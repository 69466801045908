<template>
    <div class="pinpaibaike">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
        <div class="header-middle">
            <div class="con">
                <div class="yz-row header_inner">
                    <div class="yz-column whb-visible-lg">
                        <div class="header-logo">
                            <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid" 
							src="../assets/images/logos.png" alt="同骋天下"></a>
                        </div>

                    </div>
                    <div class="yz-column zhong whb-visible-lg">
                        <div class="con text-right">
                            <ul class="menu">
                                <li class="position-relative"><a href="/"><span>首页</span></a></li>
                                
                                <li class="position-relative">
                                    <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a> 
                                        <a href="/cpzx/" class="a-hover hlist">产品介绍</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                       
                                        <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/video/" rel="/video/"><span>服务视频</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a> 
                                        <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                        <div class="yousan tianmao">
                            <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                        </div>
                        <div class="wx yousan" id="wechat">
                            <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/weixin.jpg" />
                        </div>
                        <div class="phone yousan wx" id="wechat">
                           <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="width: 100%;">
    <img src="../assets/images/ceramic.jpg" alt="" width="100%">
</div>
<div class="container mianbao">
    <div class="anli_nav">
        当前位置 : <a href="/">首页</a>>><a href="/cizhuanbaike/">品牌百科</a>
    </div>
    <div class="clearfix" id="anliyun">
    </div>
    <div class="cl"></div>
</div>
<div class="list-detail container clearfix">
    <div class="left-box" data-fixed="position">
      
      <rementj></rementj>
        
        <div class="title-sjsal mt30 mb20">
            <span>家居清理</span>
            <!-- <div class="more"><a href="" target="_blank" class="a-hover">查看更多 <i class="iconfont icon-gengduo1"></i></a></div> -->
        </div>
        
        <div class="describe-video-study describe-page-index-module pdt20 clearfix">
            <div class="left fl">
                
                <a href="/cizhuanbaike/cizhuanbaikeyi/127.html" target="_blank" class="img block zoomBox lazy">
                    <img src="http://newqunfa.mtaijiu.com/public/article/uploads/images/20211213/5f397747bff25375004afdd812fac3d3.jpg" class="lazy_img auto zoom" alt="百叶这么难清理，为什么还有这么多人把它放家里？" title="百叶这么难清理，为什么还有这么多人把它放家里？" width="100%">
                </a>
                <a href="/cizhuanbaike/cizhuanbaikeyi/127.html" target="_blank" class="block text-black f18 ellipsis a-hover mt5">百叶这么难清理，为什么还有这么多人把它放家里？</a><br><br>
                <a href="/cizhuanbaike/cizhuanbaikeyi/127.html" target="_blank" class="block text-color9 ellipsis f12 mt5">时间：2021-12-31 <span class="ml40">浏览数：34次</span></a>
                <a href="/cizhuanbaike/cizhuanbaikeyi/127.html" target="_blank" class="block ellipsis2 f12 text-sub detail mt14 a-hover">我们在装修中离不开的其中一种建材产品就是瓷砖,而且市面上的瓷砖的种类非常多,比如全瓷砖、半瓷砖、陶瓷砖等等, 这也是困扰到很多业主的选择,不知道他们到底有什么区别,如何选择合适的瓷砖;其实他们都有着自己的优势.今天小编 就来说说关于"瓷砖"的相关内容,希望能帮到大家....</a> 
            </div>
            <div class="right fr">
                
                <a href="/cizhuanbaike/cizhuanbaikeyi/127.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">12</div>
                        <div class="day pa f18">/31</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">百叶这么难清理，为什么还有这么多···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">我们在装修中离不开的其中一种建材产品就是瓷砖,而且市面上的瓷砖的种类非常多,比如全瓷砖、半瓷砖、陶瓷砖等等, 这也是困扰到很多业主的选择,不知道他们到底有什么区别,如何选择合适的瓷砖;其实他们都有着自己的优势.今天小编 就来说说关于"瓷砖"的相关内容,希望能帮到大家....</div>
                    </div>
                </a>
                
                <a href="/cizhuanbaike/cizhuanbaikeyi/126.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">12</div>
                        <div class="day pa f18">/31</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">地漏一般安装在哪里 位置选错易积···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">​如今瓷砖和陶瓷砖的使用越来越广泛,为了满足用户的需求,市场上不断出现品牌的瓷砖和陶瓷砖.对于很多小伙伴来说，在装修时，认为地砖和墙砖是一样的，可以混着使用，其实这非常不安全，也不很科学。今天小编就来说说关于 "瓷砖"的相关内容,希望能帮到大家....</div>
                    </div>
                </a>
                
                <a href="/cizhuanbaike/cizhuanbaikeyi/125.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">12</div>
                        <div class="day pa f18">/30</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">墙壁污渍怎么清理 老师傅教你几招···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">​外墙瓷砖主要用于建筑外墙的装饰和保护,外墙砖不仅装饰整个建筑物,同时因为其耐酸碱,物理化学性能稳定,对保护墙 体有重要作用,还能防寒保温.今天小编就来说说关于"瓷砖"的相关内容,希望能帮到大家....</div>
                    </div>
                </a>
                
            </div>
        </div>
        
        <div class="title-sjsal mt30 mb20">
            <span>装修知识</span>
            <!-- <div class="more"><a href="" target="_blank" class="a-hover">查看更多 <i class="iconfont icon-gengduo1"></i></a></div> -->
        </div>
        
        <div class="describe-video-study describe-page-index-module pdt20 clearfix">
            <div class="left fl">
                
                <a href="/cizhuanbaike/cizhuanbaikeer/72.html" target="_blank" class="img block zoomBox lazy">
                    <img src="../assets/static/upload/image/20220104/1641279037127573.jpg" class="lazy_img auto zoom" alt="这10处过时装修别做了 真的太踩坑了" title="这10处过时装修别做了 真的太踩坑了" width="100%">
                </a>
                <a href="/cizhuanbaike/cizhuanbaikeer/72.html" target="_blank" class="block text-black f18 ellipsis a-hover mt5">这10处过时装修别做了 真的太踩坑了</a><br><br>
                <a href="/cizhuanbaike/cizhuanbaikeer/72.html" target="_blank" class="block text-color9 ellipsis f12 mt5">时间：2021-04-13 <span class="ml40">浏览数：198次</span></a>
                <a href="/cizhuanbaike/cizhuanbaikeer/72.html" target="_blank" class="block ellipsis2 f12 text-sub detail mt14 a-hover">每一种新型材料的出现，在受到高度关注的同时，也容易备受争议。伴随着各种或质疑或肯定的声音，岩板在近两年来聚焦了建材行业的众多目光。按照生产国家和地区不同，岩板被分...</a> 
            </div>
            <div class="right fr">
                
                <a href="/cizhuanbaike/cizhuanbaikeer/72.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">04</div>
                        <div class="day pa f18">/13</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">这10处过时装修别做了 真的太踩···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">每一种新型材料的出现，在受到高度关注的同时，也容易备受争议。伴随着各种或质疑或肯定的声音，岩板在近两年来聚焦了建材行业的众多目光。按照生产国家和地区不同，岩板被分...</div>
                    </div>
                </a>
                
                <a href="/cizhuanbaike/cizhuanbaikeer/71.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">04</div>
                        <div class="day pa f18">/13</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">越来越多人选择在冬天装修？后悔看···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">从瓷砖生产厂家、定制家居行业到设计师，业内很多人都认为，岩板已经不属于瓷砖的范畴，它是一种新型的建材材料。而从当前的建材市场对于岩板的反响来看，确实得到了不少消费...</div>
                    </div>
                </a>
                
                <a href="/cizhuanbaike/cizhuanbaikeer/69.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">04</div>
                        <div class="day pa f18">/13</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">家里打算全部墙面铺满瓷砖，有哪些···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">对于家里地面的装修，绝大多数业主都会选择贴瓷砖或者铺木地板。相对而言，墙面的装修材料更加丰富多样，比如乳胶漆、壁纸、硅藻泥等等。由于每位业主的个人喜好不同，也会考...</div>
                    </div>
                </a>
                
            </div>
        </div>
        
        <div class="title-sjsal mt30 mb20">
            <span>家电知识</span>
            <!-- <div class="more"><a href="" target="_blank" class="a-hover">查看更多 <i class="iconfont icon-gengduo1"></i></a></div> -->
        </div>
        
        <div class="describe-video-study describe-page-index-module pdt20 clearfix">
            <div class="left fl">
                
                <a href="/cizhuanbaike/cizhuanbaikesan/74.html" target="_blank" class="img block zoomBox lazy">
                    <img src="../assets/static/upload/image/20220104/1641279156136956.jpg" class="lazy_img auto zoom" alt="壁挂液晶电视安装步骤 液晶电视注意事项" title="壁挂液晶电视安装步骤 液晶电视注意事项" width="100%">
                </a>
                <a href="/cizhuanbaike/cizhuanbaikesan/74.html" target="_blank" class="block text-black f18 ellipsis a-hover mt5">壁挂液晶电视安装步骤 液晶电视注意事项</a><br><br>
                <a href="/cizhuanbaike/cizhuanbaikesan/74.html" target="_blank" class="block text-color9 ellipsis f12 mt5">时间：2021-04-09 <span class="ml40">浏览数：217次</span></a>
                <a href="/cizhuanbaike/cizhuanbaikesan/74.html" target="_blank" class="block ellipsis2 f12 text-sub detail mt14 a-hover">每年的6月18日，已经成为了电商的年中大促节。在刚刚过去的618电商购物节，你剁手了吗？由于网络购物不但方便快捷，选择多，而且赶上大促活动的话，还有不少优惠。因此，很多消...</a> 
            </div>
            <div class="right fr">
                
                <a href="/cizhuanbaike/cizhuanbaikesan/74.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">04</div>
                        <div class="day pa f18">/09</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">壁挂液晶电视安装步骤 液晶电视注···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">每年的6月18日，已经成为了电商的年中大促节。在刚刚过去的618电商购物节，你剁手了吗？由于网络购物不但方便快捷，选择多，而且赶上大促活动的话，还有不少优惠。因此，很多消...</div>
                    </div>
                </a>
                
                <a href="/cizhuanbaike/cizhuanbaikesan/75.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">04</div>
                        <div class="day pa f18">/09</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">厨房抽油烟机安装步骤详解</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">由于玻化砖表面坚硬，光洁如镜，同时兼具实用性和美观度的优点，因此受到不少家装业主的青睐。然而，铺贴好的玻化砖也避免不了发生空鼓、脱落的问题，成为业主们装修时的顾虑...</div>
                    </div>
                </a>
                
                <a href="/cizhuanbaike/cizhuanbaikesan/76.html" target="_blank" class="block new-con mb17 clearfix">
                    <div class="time pr fl text-white">
                        <div class="month pa f36">04</div>
                        <div class="day pa f18">/09</div>
                    </div>
                    <div class="fr mr10 detail">
                        <div class="f18 text-black ellipsis a-hover pdt10 pdb5">几种常见的飘窗窗帘安装方式 你喜···</div>
                        <div class="ellipsis2 text-sub f12 a-hover con">由于瓷砖表面光洁明亮，大气美观，并且很方便清洁卫生，因此经常被铺贴在墙地面，家庭装修中也应用得比较普遍。不过，不少业主在挑选、购买瓷砖的时候，容易走入误区，比如一...</div>
                    </div>
                </a>
                
            </div>
        </div>

    </div>
    <div class="right-box">
        <div class="domesticOutfit mt20 bg-white tc construction_right" style="margin-left: -5px;padding: 27px 0;overflow: hidden;position: relative;z-index: 1;">
            <div class="doys"><span> x</span></div>
            <div class="tit tc text-title f24 pdt40 fb">
                同骋天下
            </div>
            <div class="bg-primary block mt10 mb10"></div>
            <div class="tc pdb12 f12 text-color9">用工匠标准要求自己，用工匠精神影响他人</div>
            <div class="con pdb50" style="width: 270px;">
                <div style="text-align: center;" href="#" class="text-sub pdt25 f16"><span><i class="iconfont icon-lingxing-copy f14 text-primary"></i></span>追求卓越，不断创新</div>
                <div style="text-align: center;" href="#" class="text-sub pdt25 f16"><span><i class="iconfont icon-lingxing-copy f14 text-primary"></i></span>客户至上，超客户预期</div>
                <div style="text-align: center;" href="#" class="text-sub pdt25 f16"><span><i class="iconfont icon-lingxing-copy f14 text-primary"></i></span>精益求精，付出不亚于任何人的努力</div>
            </div>
        </div>
</div>

</div>

<remencp></remencp>

        <footerbox></footerbox>
    </div>
</template>
<script>
 $(window).ready(function() {

$(window).scroll(function() {

    var pos = $(window).scrollTop();

    var bottomBox = $(".head-sub").find('.bottomBox');

    if (pos > 500) {

        $('.zyA_right').show();

    } else {

        $('.zyA_right').hide();

    }

    if (pos > 110) {

        bottomBox.addClass('fixedX');

    } else {

        bottomBox.removeClass('fixedX');

    }

});

$('.case-ban li').click(function() {

    var n = $(this).index();

    var t = $('.i-main .sign').eq(n).offset().top;

    $('body,html').animate({

        'scrollTop': t - 100

    });

})

$('.anli_pro .content a').wrap("<span></span>");

// $('.anli_pro .content b').hide();

var swiper = new Swiper('.swiper-container', {

    slidesPerView: 3,

    spaceBetween: 30,

    navigation: {

        nextEl: '.swiper-button-next',

        prevEl: '.swiper-button-prev',

    },



});

})
$(function() {

$(document).scroll(function() {

    var scroH = $(document).scrollTop(); //滚动高度



    if (scroH > 400) {

        $('#headerhome').css("position", "fixed");

    } else {

        $('#headerhome').css("position", "inherit");

    }

    if (scroH < 550) {
        $('.construction_right').css("position", "static");
    } else if (scroH >= 550) {
        $('.construction_right').css({
            "position": "sticky",
            "top": "15%",
            "left": "66%"
        });

    }


})

$(".menu li").mouseover(function() {

    $(this).css({

        "color": "#FF6602"

    }).siblings().css({

        "color": "#101920"

    });

})

$(".menu li").mouseout(function() {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
$('.domesticOutfit .doys span').click(function() {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
// $('.position-relative').hover(function() {

//     $(this).find('.hnav-second').stop().slideToggle("500");

//     $(this).find('.hnav').stop().slideToggle("500");

// });
$("#footer .share a").hover(function(e) {
    $(this).find("figure").fadeToggle('fast');
});

var urlstr = location.href; //获取浏览器的url

var urlstatus = false; //标记

$("#headerhome .menu li>a").each(function() {

    //判断导航里面的rel和url地址是否相等

    if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

        $(this).find('.io').addClass('ms');

        $(this).css("color", "#FF6602");

        urlstatus = true;

    } else {

        $(this).find('.io').removeClass('ms');

        $(this).css("color", "#101920");

    }

});

//当前样式保持

if (!urlstatus) {

    $("#menu a").eq(0).addClass('cur');

}



$('b span:last').after('</br>');

})
$(function() {

$(".mobile").hover(function() {

    $(this).find(".mobilecode").css({

        "display": "block"

    })

}, function() {

    $(this).find(".mobilecode").css({

        "display": "none"

    })

})

$(".side ul li").hover(function() {

    $(this).find(".sidebox").stop().css({

        "opacity": "1",

        "filter": "Alpha(opacity=100)",

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "block"

    })



}, function() {

    $(this).find(".sidebox").stop().animate({

        "width": "54px"

    }, 200).css({

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "none"

    })

});

$(window).scroll(function() {

    if ($(window).scrollTop() > 100) {

        $("#back-to-top").fadeIn(300);

    } else {

        $("#back-to-top").fadeOut(300);

    }

});

});
import footerbox from '../components/footerbox.vue'
import remencp from '../components/remencp.vue'
import rementj from '../components/rementj.vue'


export default {
    name: 'pinpaibaike',
    components: {
        footerbox,remencp,rementj
    },
    data() {
        return {
        }
    },


}
</script>
<style>
    .describe-page-index-module .right .new-con .detail .con {
        padding-left: 0;
    }
    
    .doys {
        position: absolute;
        right: 17px;
        font-size: 35px;
        top: 20px;
        color: #ccc;
        cursor: pointer;
        opacity: 0;
    }
    
    .domesticOutfit:hover .doys {
        opacity: 1;
    }
</style>