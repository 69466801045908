import axios from 'axios'

// create an axios instance: <https://axios-http.com/docs/instance>
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10 * 1000
})
console.log(process.env.VUE_APP_BASE_URL)
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
