<template>
    <div class="yyanldetail">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
            <div class="header-middle">
                <div class="con">
                    <div class="yz-row header_inner">
                        <div class="yz-column whb-visible-lg">
                            <div class="header-logo">
                                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                                        src="../assets/images/logos.png" alt="同骋天下"></a>
                            </div>

                        </div>
                        <div class="yz-column zhong whb-visible-lg">
                            <div class="con text-right">
                                <ul class="menu">
                                    <li class="position-relative"><a href="/"><span>首页</span></a></li>

                                    <li class="position-relative">
                                        <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>
                                            <a href="/cpzx/" class="a-hover hlist">产品介绍</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/video/" rel="/video/"><span>服务视频</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>
                                            <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span
                                                class="io" style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                            <div class="yousan tianmao">
                                <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                            </div>
                            <div class="wx yousan" id="wechat">
                                <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/weixin.jpg" />
                            </div>
                            <div class="phone yousan wx" id="wechat">
                                <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="width: 100%;">
            <img src="../assets/images/ceramicc.jpg" alt="" width="100%">
        </div>
        <div class="container mianbao">
            <div class="anli_nav">
                当前位置 ： <a href="/">首页</a>>><a href="/yingyonganli/">工程案例</a>>><a href="/yingyonganli/gwal/">装饰风格</a>
            </div>
            <div class="clearfix" id="anliyun">
            </div>
            <div class="cl"></div>
        </div>
        <div class="container customerEvaluationDetails">
            <div class="detailsLeft fl" data-fixed="position">
                <!-- 正文 -->
                <div class="f26 text-title tc lh1 pdt10">
                    <h1>{{ info.title }}</h1>
                </div>
                <div class="tc pdt20 pdb10">
                    <span class="text-color9">浏览数：{{info.view}}次</span>
                    <span class="pdl38 text-color9">更新时间：</span>
                    <span class="text-title mr20">{{ info.updateTime }}</span>
                    <a class="iconfont icon-fenxiang a-hover mr10" title="分享"></a>
                </div>

                <div class="article-content pdt20">
                    <div v-html="info.content">
                    </div>
                    <p>
                        &nbsp;</p>
                </div>
                <div class="bg-border mt20 mb20" style="width:880px;height:1px"></div>
                <div class="share pdt10 clearfix">
                    <div class="icon2 f16 fr a-hover pointer">
                    </div>
                </div>
                <div class="caseBtn">
                    <div class="box ellipsis" style="height:74px;line-height:74px">
                        <i></i>上一篇：<a href="/yingyonganli/gwal/6.html">金科王府 简美</a>
                    </div>
                    <div class="box ellipsis" style="height:74px;line-height:74px">
                        <i></i>下一篇：没有了！
                    </div>
                </div>
            </div>
            <div class="construction_right clearfix fr">
                <div class="domesticOutfit mt20 bg-white tc"
                    style="margin-left: -5px;padding: 27px 0;overflow: hidden;position: relative;">
                    <div class="doys"><span> x</span></div>
                    <div class="tit tc text-title f24 pdt40 fb">
                        招商优势
                    </div>
                    <div class="bg-primary block mt10 mb10"></div>
                    <div class="tc pdb12 text-color9">招商6大优势</div>
                    <div class="con pdb50" style="width: 270px;">
                        <div href="#" class="text-sub pdt25 f16 ellipsis" style="width: 100%;"><span><i
                                    class="iconfont icon-lingxing-copy f14 text-primary"></i></span>全国连锁安装师傅用工平台</div>
                        <div href="#" class="text-sub pdt25 f16 ellipsis" style="width: 100%;"><span><i
                                    class="iconfont icon-lingxing-copy f14 text-primary"></i></span>总部提供客源，利润分成</div>
                        <div href="#" class="text-sub pdt25 f16 ellipsis" style="width: 100%;"><span><i
                                    class="iconfont icon-lingxing-copy f14 text-primary"></i></span>成熟模式复制，全程扶持盈利</div>
                        <div href="#" class="text-sub pdt25 f16 ellipsis" style="width: 100%;"><span><i
                                    class="iconfont icon-lingxing-copy f14 text-primary"></i></span>平台前台后台，独立经营</div>
                        <div href="#" class="text-sub pdt25 f16 ellipsis" style="width: 100%;"><span><i
                                    class="iconfont icon-lingxing-copy f14 text-primary"></i></span>商家联盟，持续盈利</div>
                        <div href="#" class="text-sub pdt25 f16 ellipsis" style="width: 100%;"><span><i
                                    class="iconfont icon-lingxing-copy f14 text-primary"></i></span>家装家政客户服务 终身维护</div>
                    </div>
                </div>
            </div>
            <div class="cl"></div>

        </div>



        <div class="list-detail container clearfix">
            <div class="left-box" data-fixed="position">
                <rementj></rementj>
            </div>
        </div>
        <footerbox></footerbox>
    </div>
</template>
<script>
$(window).ready(function () {

    $(window).scroll(function () {

        var pos = $(window).scrollTop();

        var bottomBox = $(".head-sub").find('.bottomBox');

        if (pos > 500) {

            $('.zyA_right').show();

        } else {

            $('.zyA_right').hide();

        }

        if (pos > 110) {

            bottomBox.addClass('fixedX');

        } else {

            bottomBox.removeClass('fixedX');

        }

    });

    $('.case-ban li').click(function () {

        var n = $(this).index();

        var t = $('.i-main .sign').eq(n).offset().top;

        $('body,html').animate({

            'scrollTop': t - 100

        });

    })

    $('.anli_pro .content a').wrap("<span></span>");

    // $('.anli_pro .content b').hide();

    var swiper = new Swiper('.swiper-container', {

        slidesPerView: 3,

        spaceBetween: 30,

        navigation: {

            nextEl: '.swiper-button-next',

            prevEl: '.swiper-button-prev',

        },



    });

})
$(function () {

    $(document).scroll(function () {

        var scroH = $(document).scrollTop(); //滚动高度



        if (scroH > 400) {

            $('#headerhome').css("position", "fixed");

        } else {

            $('#headerhome').css("position", "inherit");

        }

        if (scroH < 550) {
            $('.construction_right').css("position", "static");
        } else if (scroH >= 550) {
            $('.construction_right').css({
                "position": "sticky",
                "top": "15%",
                "left": "66%"
            });

        }


    })

    $(".menu li").mouseover(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })

    $(".menu li").mouseout(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
    $('.domesticOutfit .doys span').click(function () {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
    // $('.position-relative').hover(function() {

    //     $(this).find('.hnav-second').stop().slideToggle("500");

    //     $(this).find('.hnav').stop().slideToggle("500");

    // });
    $("#footer .share a").hover(function (e) {
        $(this).find("figure").fadeToggle('fast');
    });

    var urlstr = location.href; //获取浏览器的url

    var urlstatus = false; //标记

    $("#headerhome .menu li>a").each(function () {

        //判断导航里面的rel和url地址是否相等

        if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

            $(this).find('.io').addClass('ms');

            $(this).css("color", "#FF6602");

            urlstatus = true;

        } else {

            $(this).find('.io').removeClass('ms');

            $(this).css("color", "#101920");

        }

    });

    //当前样式保持

    if (!urlstatus) {

        $("#menu a").eq(0).addClass('cur');

    }



    $('b span:last').after('</br>');

})
$(function () {

    $(".mobile").hover(function () {

        $(this).find(".mobilecode").css({

            "display": "block"

        })

    }, function () {

        $(this).find(".mobilecode").css({

            "display": "none"

        })

    })

    $(".side ul li").hover(function () {

        $(this).find(".sidebox").stop().css({

            "opacity": "1",

            "filter": "Alpha(opacity=100)",

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "block"

        })



    }, function () {

        $(this).find(".sidebox").stop().animate({

            "width": "54px"

        }, 200).css({

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "none"

        })

    });

    $(window).scroll(function () {

        if ($(window).scrollTop() > 100) {

            $("#back-to-top").fadeIn(300);

        } else {

            $("#back-to-top").fadeOut(300);

        }

    });

});
import footerbox from '../components/footerbox.vue'
import rementj from '../components/rementj.vue'

export default {
    name: 'yyanldetail',
    components: {
        footerbox,rementj
    },
    data() {
        return {
            info:{}
        }
    },
    mounted(){
        console.log() // mick
        
        this.$api.common.engineeringDetail({id:this.getQueryString('id')}).then(res => {
            console.log(res)
            this.info = res.data.result

        }).catch(res => {

        })
    },
    methods: {
        getQueryString(name) {
            const url_string = window.location.href; // window.location.href
            const url = new URL(url_string);
            return url.searchParams.get(name);
        }


    }


}
</script>
<style>.doys {
    position: absolute;
    right: 17px;
    font-size: 35px;
    top: 20px;
    color: #ccc;
    cursor: pointer;
    opacity: 0;
}

.domesticOutfit:hover .doys {
    opacity: 1;
}</style>