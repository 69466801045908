import common from '../api/common'
// ...

class API {
    constructor () {
        this.common = common
        // ...
    }
}

// 导出使用
export default new API()
