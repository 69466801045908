<template>
    <div class="hezuohuoban">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
            <div class="header-middle">
                <div class="con">
                    <div class="yz-row header_inner">
                        <div class="yz-column whb-visible-lg">
                            <div class="header-logo">
                                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                                        src="../assets/images/logos.png" alt="同骋天下"></a>
                            </div>

                        </div>
                        <div class="yz-column zhong whb-visible-lg">
                            <div class="con text-right">
                                <ul class="menu">
                                    <li class="position-relative"><a href="/"><span>首页</span></a></li>

                                    <li class="position-relative">
                                        <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>
                                            <a href="/cpzx/" class="a-hover hlist">产品介绍</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/video/" rel="/video/"><span>服务视频</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>
                                            <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span
                                                class="io" style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/jiamenghezuo/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                            <div class="yousan tianmao">
                                <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                            </div>
                            <div class="wx yousan" id="wechat">
                                <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/weixin.jpg" />
                            </div>
                            <div class="phone yousan wx" id="wechat">
                                <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="width: 100%;">
            <img src="../assets/images/cooperation.jpg" alt="" width="100%">
        </div>
        <div class="container mianbao">
            <div class="anli_nav">
                当前位置 ： <a href="/">首页</a>>><a href="/zhaoshangjiameng/">招商加盟</a>>><a href="/hezuohuoban/">合作伙伴</a>
            </div>
            <div class="clearfix" id="anliyun">
            </div>
            <div class="cl"></div>
        </div>
        <div class="container-list">

            <ul class="container">


                <li><a href="/zhaoshangjiameng/">加盟合作</a></li>
                <li class="aon"><a href="/hezuohuoban/">合作伙伴</a></li>

            </ul>

        </div>
        <div class="xiaoguo mt30" style="background:white;overflow: hidden;height: auto;">
            <div class="container">
                <div class="main-title" style="font-size: 30px;">战略合作</div>
                <div class="img-lists mt40 tab-layer">
                    <div style="display:block">

                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317731624642.png" class="auto zoom"
                                    alt="哔哩哔哩" title="哔哩哔哩" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317715734961.png" class="auto zoom"
                                    alt="百度好看" title="百度好看" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317705356855.png" class="auto zoom"
                                    alt="小红书" title="小红书" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317693601169.png" class="auto zoom"
                                    alt="西瓜" title="西瓜" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317685738337.png" class="auto zoom"
                                    alt="快手" title="快手" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317667259275.png" class="auto zoom"
                                    alt="抖音" title="抖音" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317652971480.png" class="auto zoom"
                                    alt="淘宝" title="淘宝" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317640482087.png" class="auto zoom"
                                    alt="滴滴出行" title="滴滴出行" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317627802620.png" class="auto zoom"
                                    alt="饿了么" title="饿了么" style="width: 120px">
                            </a>
                        </div>


                        <div class="img-list">
                            <a href="javascript:;" class="zoomBox" rel="nofollow">
                                <img src="../assets/static/upload/image/20221206/1670317616359593.png" class="auto zoom"
                                    alt="美团" title="美团" style="width: 120px">
                            </a>
                        </div>



                    </div>
                </div>
            </div>
        </div>

        <div class="xiaoguo mt30" style="background:white;overflow: hidden;height: auto;">
            <div class="container">
                <div class="main-title" style="font-size: 30px;">合作商家</div>
                <div class="img-lists mt40 tab-layer">
                    <div style="display:block">
                        <img src="../assets/static/upload/image/20221206/1670318097213479.png" alt="合作商家" title="合作商家"
                            style="max-width: 100%;">

                    </div>
                </div>
            </div>
        </div>
        <footerbox></footerbox>
    </div>
</template>
<script>
$(window).ready(function () {

    $(window).scroll(function () {

        var pos = $(window).scrollTop();

        var bottomBox = $(".head-sub").find('.bottomBox');

        if (pos > 500) {

            $('.zyA_right').show();

        } else {

            $('.zyA_right').hide();

        }

        if (pos > 110) {

            bottomBox.addClass('fixedX');

        } else {

            bottomBox.removeClass('fixedX');

        }

    });

    $('.case-ban li').click(function () {

        var n = $(this).index();

        var t = $('.i-main .sign').eq(n).offset().top;

        $('body,html').animate({

            'scrollTop': t - 100

        });

    })

    $('.anli_pro .content a').wrap("<span></span>");

    // $('.anli_pro .content b').hide();

    var swiper = new Swiper('.swiper-container', {

        slidesPerView: 3,

        spaceBetween: 30,

        navigation: {

            nextEl: '.swiper-button-next',

            prevEl: '.swiper-button-prev',

        },



    });

})
$(function () {

    $(document).scroll(function () {

        var scroH = $(document).scrollTop(); //滚动高度



        if (scroH > 400) {

            $('#headerhome').css("position", "fixed");

        } else {

            $('#headerhome').css("position", "inherit");

        }

        if (scroH < 550) {
            $('.construction_right').css("position", "static");
        } else if (scroH >= 550) {
            $('.construction_right').css({
                "position": "sticky",
                "top": "15%",
                "left": "66%"
            });

        }


    })

    $(".menu li").mouseover(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })

    $(".menu li").mouseout(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
    $('.domesticOutfit .doys span').click(function () {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
    // $('.position-relative').hover(function() {

    //     $(this).find('.hnav-second').stop().slideToggle("500");

    //     $(this).find('.hnav').stop().slideToggle("500");

    // });
    $("#footer .share a").hover(function (e) {
        $(this).find("figure").fadeToggle('fast');
    });

    var urlstr = location.href; //获取浏览器的url

    var urlstatus = false; //标记

    $("#headerhome .menu li>a").each(function () {

        //判断导航里面的rel和url地址是否相等

        if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

            $(this).find('.io').addClass('ms');

            $(this).css("color", "#FF6602");

            urlstatus = true;

        } else {

            $(this).find('.io').removeClass('ms');

            $(this).css("color", "#101920");

        }

    });

    //当前样式保持

    if (!urlstatus) {

        $("#menu a").eq(0).addClass('cur');

    }



    $('b span:last').after('</br>');

})
$(function () {

    $(".mobile").hover(function () {

        $(this).find(".mobilecode").css({

            "display": "block"

        })

    }, function () {

        $(this).find(".mobilecode").css({

            "display": "none"

        })

    })

    $(".side ul li").hover(function () {

        $(this).find(".sidebox").stop().css({

            "opacity": "1",

            "filter": "Alpha(opacity=100)",

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "block"

        })



    }, function () {

        $(this).find(".sidebox").stop().animate({

            "width": "54px"

        }, 200).css({

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "none"

        })

    });

    $(window).scroll(function () {

        if ($(window).scrollTop() > 100) {

            $("#back-to-top").fadeIn(300);

        } else {

            $("#back-to-top").fadeOut(300);

        }

    });

});
import footerbox from '../components/footerbox.vue'
export default {
    name: 'hezuohuoban',
    components: {
        footerbox
    },
    data() {
        return {
        }
    },
    methods: {

    }


}
</script>
<style>.xiaoguo .img-lists .img-list {
    width: 15%;
}

.xiaoguo .img-lists .img-list img {}

.xiaoguo .img-lists .img-list img:hover {
    filter: none;
}</style>