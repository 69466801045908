<template>
    <div class="meitidongtai">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
            <div class="header-middle">
                <div class="con">
                    <div class="yz-row header_inner">
                        <div class="yz-column whb-visible-lg">
                            <div class="header-logo">
                                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                                        src="../assets/images/logos.png" alt="同骋天下"></a>
                            </div>

                        </div>
                        <div class="yz-column zhong whb-visible-lg">
                            <div class="con text-right">
                                <ul class="menu">
                                    <li class="position-relative"><a href="/"><span>首页</span></a></li>

                                    <li class="position-relative">
                                        <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>
                                            <a href="/cpzx/" class="a-hover hlist">产品介绍</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/video/" rel="/video/"><span>服务视频</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>
                                            <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span
                                                class="io" style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                            <div class="yousan tianmao">
                                <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                            </div>
                            <div class="wx yousan" id="wechat">
                                <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/weixin.jpg" />
                            </div>
                            <div class="phone yousan wx" id="wechat">
                                <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="width: 100%;">
            <img src="../assets/images/news.jpg" alt="" width="100%">
        </div>
        <div class="container mianbao">
            <div class="anli_nav"> 当前位置 ： <a href="/">首页</a>>><a href="/meitidongtai/">媒体动态</a> </div>
            <div class="clearfix" id="anliyun">
            </div>
            <div class="cl"></div>
        </div>
        <div class="container clearfix pr">
            <div class="gydy-left">
                <div class="l-head"></div> <a 
                    class="block pr list tc f16 text-black a-hover " @click="isactive=1" :class="isactive==1?'active':''">新闻中心</a>
                <a  class="block pr list tc f16 text-black a-hover " @click="isactive=2" :class="isactive==2?'active':''">行业动态</a>
                <a href="/lianxiwomen/" class="block pr list tc f16 text-black a-hover " @click="isactive=3" :class="isactive==3?'active':''">联系我们</a>
            </div>
            <div class="gydy-right clearfix">
                <div class="c-a love mb20 clearfix" v-for="item, index in list" :key="index">
                    <a class="block img lazy zoomBox fl" target="_blank" :href="'/mtdddetail?id='+item.id">
                        <img class="lazy_img zoom" :src="item.image" alt="">
                    </a>
                    <div class="content fr">
                        <h2 class="ellipsis">
                            <a target="_blank" :href="'/mtdddetail?id='+item.id"
                                class="text-black f18 a-hover">{{ item.title }}</a>
                        </h2>
                        <p class="ellipsis4 describe mt20">
                            <a class="text-sub f14 a-hover" target="_blank"
                                :href="'/mtdddetail?id='+item.id">{{ item.introduce }}</a>
                        </p>
                        <p class="text-color9 f14 ellipsis mt20">发布时间：{{ item.createTime }}</p>
                    </div>
                </div>
                
                <div style="clear: both;"></div>
                <div class="pagess" style="padding-top: 0;">
            <ul> <span class='page-status'>共{{total}}条 当前{{page}}/{{total}}页</span>
                <span class='page-index'><a @click="getList(1)">首页</a></span>
                <span class='page-pre'><a @click="getList(page - 1)">前一页</a></span>
                <span class='page-numbar' v-for="item,index in Array.from({length: page0}, (val, i) => i)" :key="index">
                    <a @click="getList(item+1)" class="page-num page-num-current">{{ item+1 }}</a>
                </span>
                <span class='page-next'><a @click="getList(page+1)">后一页</a></span><span class='page-last'><a
                    @click="getList(page0)">尾页</a></span>
            </ul>
        </div>
        </div>
    </div>
    <div class="list-detail container clearfix" style="width:800px">
        <div class="left-box" data-fixed="position">
            <Rementj></Rementj>
        </div>
    </div>
    <footerbox></footerbox>
</div></template>
<script>
$(window).ready(function() {

$(window).scroll(function() {

    var pos = $(window).scrollTop();

    var bottomBox = $(".head-sub").find('.bottomBox');

    if (pos > 500) {

        $('.zyA_right').show();

    } else {

        $('.zyA_right').hide();

    }

    if (pos > 110) {

        bottomBox.addClass('fixedX');

    } else {

        bottomBox.removeClass('fixedX');

    }

});



$('.anli_pro .content a').wrap("<span></span>");

// $('.anli_pro .content b').hide();

var swiper = new Swiper('.swiper-container', {

    slidesPerView: 3,

    spaceBetween: 30,

    navigation: {

        nextEl: '.swiper-button-next',

        prevEl: '.swiper-button-prev',

    },



});

})
$(function() {

$(document).scroll(function() {

    var scroH = $(document).scrollTop(); //滚动高度



    if (scroH > 400) {

        $('#headerhome').css("position", "fixed");

    } else {

        $('#headerhome').css("position", "inherit");

    }

    if (scroH < 550) {
        $('.construction_right').css("position", "static");
    } else if (scroH >= 550) {
        $('.construction_right').css({
            "position": "sticky",
            "top": "15%",
            "left": "66%"
        });

    }


})

$(".menu li").mouseover(function() {

    $(this).css({

        "color": "#FF6602"

    }).siblings().css({

        "color": "#101920"

    });

})

$(".menu li").mouseout(function() {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
$('.domesticOutfit .doys span').click(function() {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
// $('.position-relative').hover(function() {

//     $(this).find('.hnav-second').stop().slideToggle("500");

//     $(this).find('.hnav').stop().slideToggle("500");

// });
$("#footer .share a").hover(function(e) {
    $(this).find("figure").fadeToggle('fast');
});

var urlstr = location.href; //获取浏览器的url

var urlstatus = false; //标记

$("#headerhome .menu li>a").each(function() {

    //判断导航里面的rel和url地址是否相等

    if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

        $(this).find('.io').addClass('ms');

        $(this).css("color", "#FF6602");

        urlstatus = true;

    } else {

        $(this).find('.io').removeClass('ms');

        $(this).css("color", "#101920");

    }

});

//当前样式保持

if (!urlstatus) {

    $("#menu a").eq(0).addClass('cur');

}



$('b span:last').after('</br>');

})
$(function() {

$(".mobile").hover(function() {

    $(this).find(".mobilecode").css({

        "display": "block"

    })

}, function() {

    $(this).find(".mobilecode").css({

        "display": "none"

    })

})

$(".side ul li").hover(function() {

    $(this).find(".sidebox").stop().css({

        "opacity": "1",

        "filter": "Alpha(opacity=100)",

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "block"

    })



}, function() {

    $(this).find(".sidebox").stop().animate({

        "width": "54px"

    }, 200).css({

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "none"

    })

});

$(window).scroll(function() {

    if ($(window).scrollTop() > 100) {

        $("#back-to-top").fadeIn(300);

    } else {

        $("#back-to-top").fadeOut(300);

    }

});

});
import Rementj from '@/components/rementj.vue';
import footerbox from '../components/footerbox.vue'
export default {
    name: 'meitidongtai',
    components: {
        footerbox,
        Rementj
    },
    data() {
        return {
            list: [],
            page: 1,
            size: 4,
            total:0,
            page0:0,
            isactive:1
        }
    },
    mounted() {
        this.getList(1)
    },
    methods: {
        getList(page) {
            if(page<=0){
                page=1
            }
            this.page = page
            this.list = []
            this.$api.common.newsList({ page: this.page, size: this.size }).then(res => {
     
                this.total = res.data.result.total
                this.list = res.data.result.list
                this.page0 = parseInt(this.total/this.size)+1


            }).catch(res => {

            })

        },
    }


}
</script>