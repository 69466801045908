<template>
    <div class="pinpaijieshao">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
            <div class="header-middle">
                <div class="con">
                    <div class="yz-row header_inner">
                        <div class="yz-column whb-visible-lg">
                            <div class="header-logo">
                                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                                        src="../assets/images/logos.png" alt="同骋天下"></a>
                            </div>

                        </div>
                        <div class="yz-column zhong whb-visible-lg">
                            <div class="con text-right">
                                <ul class="menu">
                                    <li class="position-relative"><a href="/"><span>首页</span></a></li>

                                    <li class="position-relative">
                                        <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>
                                            <a href="/cpzx/" class="a-hover hlist">产品介绍</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/video/" rel="/video/"><span>服务视频</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>
                                            <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span
                                                class="io" style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                            <div class="yousan tianmao">
                                <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                            </div>
                            <div class="wx yousan" id="wechat">
                                <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/weixin.jpg" />
                            </div>
                            <div class="phone yousan wx" id="wechat">
                                <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="/skin/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="width: 100%;">
            <img src="../assets/images/brand.jpg" alt="" width="100%">
        </div>
        <div class="container mianbao">
            <div class="anli_nav">
                当前位置 ： <a href="/">首页</a>>><a href="/pinpaijieshao/">品牌介绍</a>
            </div>
            <div class="clearfix" id="anliyun">
            </div>
            <div class="cl"></div>
        </div>
        <div class="container-list">

            <ul class="container">


                <li class="aon">
                    <a href="/pinpaijieshao/">关于我们</a>
                </li>
                <li>
                    <a href="/fazhanlicheng/">发展历程</a>
                </li>
                <li>
                    <a href="/guanyuwomen/">品牌介绍</a>
                </li>
                <li>
                    <a href="/lianxiwomen/">联系我们</a>
                </li>

            </ul>

        </div>
        <section class="page_content">
            <div class="brand_1 container">
                <div class="page_title">
                    <h4>同骋工匠（同聘工匠），值得拥有</h4>
                    <p>Tongcheng craftsman, you deserve it</p>
                </div>
                <div class="brand_1_content">
                    <p> 同骋工匠，全国连锁家装师傅用工平台，是同骋天下（北京）科技有限公司旗下的核心项目之一，公司成立于2020年10月30日，公司总部坐落于首都北京市。</p>
                    <p> 平台致力于搭建一个商户、师傅与业主间的无障碍桥梁，整合同城师傅资源，提升行业效率，建造一个家装、家政一站式综合服务平台，平台倡导工匠精神，用工匠标准要求自己，用工匠精神服务他人。</p>
                    <div><img src="../assets/images/banner.jpg"></div>
                </div>
            </div>
            <div class="brand_2">
                <div class="container" style="height: 100%;">
                    <div class="left brand_2_img"><img src="../assets/images/about2.jpg"></div>
                    <section class="left">
                        <h5>同骋工匠（同聘工匠）</h5>
                        <p>同骋天下（北京）科技有限公司位于首都城市北京市，主营业务包括装修、家居安装、家居维护、油烟机清理、壁纸铺贴、玻璃清洁、厨宝安装、窗帘安装，
                            地板保养、灯饰安装、电动晾衣架安装、家具贴膜、日常保洁。
                        </p>
                        <p>
                            通过与代理商、工匠师傅的合作，已经走出了最重要的一步、相信他会走进千家万户。
                        </p>
                    </section>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div> <img alt="" src="../assets/images/cxcg.jpg" style="width: 1200px; height: 673px;"></div>
            </div>
            <div class="brand_2">
                <div class="container" style="height: 100%;">
                    <section class="left">
                        <h5>我们的优势</h5>
                        <p>多端口进入</p>
                        <p>不占用手机内存，无需下载</p>
                        <p>关于服务：让用户的每一次服务都能享受到专业的团队、标准的流程、超值的服务</p>
                        <p>关于师傅：实名认证、严查审核、流程把控 </p>
                    </section>
                    <div class="left brand_2_img"><img src="../assets/images/about1.jpg"></div>
                </div>
            </div>
        </section>
        <footerbox></footerbox>
    </div>
</template>
<script>
$(window).ready(function () {

    $(window).scroll(function () {

        var pos = $(window).scrollTop();

        var bottomBox = $(".head-sub").find('.bottomBox');

        if (pos > 500) {

            $('.zyA_right').show();

        } else {

            $('.zyA_right').hide();

        }

        if (pos > 110) {

            bottomBox.addClass('fixedX');

        } else {

            bottomBox.removeClass('fixedX');

        }

    });

    $('.case-ban li').click(function () {

        var n = $(this).index();

        var t = $('.i-main .sign').eq(n).offset().top;

        $('body,html').animate({

            'scrollTop': t - 100

        });

    })

    $('.anli_pro .content a').wrap("<span></span>");

    // $('.anli_pro .content b').hide();

    var swiper = new Swiper('.swiper-container', {

        slidesPerView: 3,

        spaceBetween: 30,

        navigation: {

            nextEl: '.swiper-button-next',

            prevEl: '.swiper-button-prev',

        },



    });

})
$(function () {

    $(document).scroll(function () {

        var scroH = $(document).scrollTop(); //滚动高度



        if (scroH > 400) {

            $('#headerhome').css("position", "fixed");

        } else {

            $('#headerhome').css("position", "inherit");

        }

        if (scroH < 550) {
            $('.construction_right').css("position", "static");
        } else if (scroH >= 550) {
            $('.construction_right').css({
                "position": "sticky",
                "top": "15%",
                "left": "66%"
            });

        }


    })

    $(".menu li").mouseover(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })

    $(".menu li").mouseout(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
    $('.domesticOutfit .doys span').click(function () {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
    // $('.position-relative').hover(function () {

    //     $(this).find('.hnav-second').stop().slideToggle("500");

    //     $(this).find('.hnav').stop().slideToggle("500");

    // });
    $("#footer .share a").hover(function (e) {
        $(this).find("figure").fadeToggle('fast');
    });

    var urlstr = location.href; //获取浏览器的url

    var urlstatus = false; //标记

    $("#headerhome .menu li>a").each(function () {

        //判断导航里面的rel和url地址是否相等

        if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

            $(this).find('.io').addClass('ms');

            $(this).css("color", "#FF6602");

            urlstatus = true;

        } else {

            $(this).find('.io').removeClass('ms');

            $(this).css("color", "#101920");

        }

    });

    //当前样式保持

    if (!urlstatus) {

        $("#menu a").eq(0).addClass('cur');

    }



    $('b span:last').after('</br>');

})
$(function () {

    $(".mobile").hover(function () {

        $(this).find(".mobilecode").css({

            "display": "block"

        })

    }, function () {

        $(this).find(".mobilecode").css({

            "display": "none"

        })

    })

    $(".side ul li").hover(function () {

        $(this).find(".sidebox").stop().css({

            "opacity": "1",

            "filter": "Alpha(opacity=100)",

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "block"

        })



    }, function () {

        $(this).find(".sidebox").stop().animate({

            "width": "54px"

        }, 200).css({

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "none"

        })

    });

    $(window).scroll(function () {

        if ($(window).scrollTop() > 100) {

            $("#back-to-top").fadeIn(300);

        } else {

            $("#back-to-top").fadeOut(300);

        }

    });

});
import footerbox from '../components/footerbox.vue'
export default {
    name: 'pinpaijieshao',
    components: {
        footerbox
    },
    data() {
        return {
        }
    },


}
</script>