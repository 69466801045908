<template>
  <div id="footer">

    <div class="container clearfix">

      <div class="fl">

        <figure><img src="../assets/images/logoss.png" width="50%"> </figure>

        <div class="share">

          <div>

            <a href="mailto:2871188486@qq.com" class="yx">

            </a>

            <a href="" class="sina">

              <figure>

                <!-- <img src="/"> -->

              </figure>

            </a>

            <a href="" class="wx">

              <figure><img src="../assets/images/weixin.jpg"> </figure>

            </a>

            <a href="" class="xcx">

              <figure><img src="../assets/images/xiaocx.jpg"> </figure>

            </a>

            <a href="" class="dy">

            </a>

            <a href="" class="xhs">

            </a>

          </div>

        </div>

        <div class="tel">

          <p>服务热线</p>

          <h3>13353433133</h3>

          <p>联系地址</p><br>

          <span style="font-size: 12px;">北京市平谷区夏各庄镇马各庄南街83号</span>

        </div>

      </div>

      <div class="fr">

        <div class="tit">

        </div>

        <nav class="clearfix">



          <dl id="foot59">

            <dt> <a href="/cpzx/">产品中心</a></dt>

            <dd><a href="/xiazaizhongxin/">下载中心</a></dd>



            <dd><a href="/cpzx/">产品介绍</a></dd>



          </dl>



          <dl id="foot59">

            <dt> <a href="/yingyonganli/">工程案例</a></dt>

          </dl>



          <dl id="foot59">

            <dt> <a href="/pinpaijieshao/">品牌介绍</a></dt>

            <dd><a href="/pinpaijieshao/">关于我们</a></dd>



            <dd><a href="/fazhanlicheng/">发展历程</a></dd>



            <dd><a href="/guanyuwomen/">品牌介绍</a></dd>



            <dd><a href="/lianxiwomen/">联系我们</a></dd>



          </dl>



          <dl id="foot59">

            <dt> <a href="/cizhuanbaike/">品牌百科</a></dt>

          </dl>



          <dl id="foot59">

            <dt> <a href="/video/">服务视频</a></dt>

          </dl>



          <dl id="foot59">

            <dt> <a href="/meitidongtai/">媒体动态</a></dt>

            <dd><a href="/xinwenzhongxin/">新闻中心</a></dd>



            <dd><a href="/xinpinfabu/">行业动态</a></dd>



          </dl>



          <dl id="foot59">

            <dt> <a href="/zhaoshangjiameng/">招商加盟</a></dt>

            <dd><a href="/zhaoshangjiameng/">加盟合作</a></dd>



            <dd><a href="/hezuohuoban/">合作伙伴</a></dd>



          </dl>



        </nav>

        <p>

          Copyright © 2021 同骋天下（北京）科技有限公司 版权所有&nbsp;备案号：<a href="https://beian.miit.gov.cn/" target="_blank"
            rel="nofollow">京ICP备2021000350号-1</a> <span style="margin-left:15px;">技术支持：<a
              href="https://www.shenmengnet.cn" title="申梦网络" target="_blank" rel="nofollow">同骋工匠</a></span>





        </p>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14010502051115"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-left:30px"><img
            src="../assets/images/beian.png" style="float:left;" /><b
            style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">晋公网安备
            14010502051115号</b></a>

      </div>


       



      <div class="clear"></div>
    </div>
  </div>
</template>
  
<script>

$(function () {
  $("#footer .share a").hover(function (e) {

    $(this).find("figure").fadeToggle('fast');

  });
})
export default {
  name: 'footerbox',
  props: {

  }
}

</script>
  
<style scoped>
dd {
  margin-left: 0;
}
</style>
  