import Vue from 'vue'
//1.导⼊
import vueRouter from 'vue-router'
import Home from './views/Home.vue'
import cpzx from './views/cpzx.vue'
import ppjs from './views/pinpaijieshao.vue'
import yyal from './views/yingyonganli.vue'
import xzzx from './views/xiazaizhongxin.vue'
import cpzxdetail from './views/cpzxdetail.vue'
import yyanldetail from './views/yyanldetail.vue'
import guanyuwomen from './views/guanyuwomen.vue'
import lianxiwomen from './views/lianxiwomen.vue'
import fazhanlicheng from './views/fazhanlicheng.vue'
import pinpaibaike from './views/pinpaibaike.vue'
import video from './views/video.vue'
import meitidongtai from './views/meitidongtai.vue'
import xinwenzhongxin from './views/xinwenzhongxin.vue'
import xinpinfabu from './views/xinpinfabu.vue'
import zhaoshangjiameng from './views/zhaoshangjiameng.vue'
import hezuohuoban from './views/hezuohuoban.vue'
import mtdddetail from './views/mtdddetail.vue'



//2.模块化机制 使⽤Router
Vue.use(vueRouter)
//3.创建路由器对象
const router = new vueRouter({
  mode: 'history', //history模式 干净的网页地址 没有#/之类的符号存在
  routes:[{
    path: '/',
    component: Home,
    meta:{
      title:'家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/cpzx',
    component: cpzx,
    meta:{
      title:'产品中心-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  
  {
    path: '/yingyonganli',
    component: yyal,
    meta:{
      title:'工程案例-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/cpzxdetail',
    component: cpzxdetail,
    meta:{
      title:'产品介绍-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/yyanldetail',
    component: yyanldetail,
    meta:{
      title:'工程案例-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/xiazaizhongxin',
    component: xzzx,
    meta:{
      title:'下载中心-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/pinpaijieshao',
    component: ppjs,
    meta:{
      title:'品牌介绍-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/fazhanlicheng',
    component: fazhanlicheng,
    meta:{
      title:'发展历程-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/lianxiwomen',
    component: lianxiwomen,
    meta:{
      title:'联系我们-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/guanyuwomen',
    component: guanyuwomen,
    meta:{
      title:'品牌介绍-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/cizhuanbaike',
    component: pinpaibaike,
    meta:{
      title:'品牌百科-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/video',
    component: video,
    meta:{
      title:'服务视频-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/meitidongtai',
    component: meitidongtai,
    meta:{
      title:'媒体动态-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/mtdddetail',
    component: mtdddetail,
    meta:{
      title:'媒体动态-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  
  {
    path: '/xinpinfabu',
    component: xinpinfabu,
    meta:{
      title:'行业动态-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/xinwenzhongxin',
    component: xinwenzhongxin,
    meta:{
      title:'新闻中心-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/zhaoshangjiameng',
    component: zhaoshangjiameng,
    meta:{
      title:'招商加盟-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  {
    path: '/hezuohuoban',
    component: hezuohuoban,
    meta:{
      title:'合作伙伴-家政服务招商加盟-家电清洗招商加盟-家电安装-地板保养-同骋工匠（同聘工匠）'

    }
  },
  
  ]
})
router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  next()
});
export default router;
