<template>
    <div class="xinwenzhongxin">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
            <div class="header-middle">
                <div class="con">
                    <div class="yz-row header_inner">
                        <div class="yz-column whb-visible-lg">
                            <div class="header-logo">
                                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                                        src="../assets/images/logos.png" alt="同骋天下"></a>
                            </div>

                        </div>
                        <div class="yz-column zhong whb-visible-lg">
                            <div class="con text-right">
                                <ul class="menu">
                                    <li class="position-relative"><a href="/"><span>首页</span></a></li>

                                    <li class="position-relative">
                                        <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>
                                            <a href="/cpzx/" class="a-hover hlist">产品介绍</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/video/" rel="/video/"><span>服务视频</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>
                                            <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span
                                                class="io" style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                            <div class="yousan tianmao">
                                <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                            </div>
                            <div class="wx yousan" id="wechat">
                                <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/weixin.jpg" />
                            </div>
                            <div class="phone yousan wx" id="wechat">
                                <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="width: 100%;">
            <img src="../assets/images/news.jpg" alt="" width="100%">
        </div>
        <div class="container mianbao">
            <div class="anli_nav"> 当前位置 ： <a href="/">首页</a>>><a href="/meitidongtai/">媒体动态</a>>><a
                    href="/xinwenzhongxin/">新闻中心</a> </div>
            <div class="clearfix" id="anliyun">
            </div>
            <div class="cl"></div>
        </div>
        <div class="container clearfix pr">
            <div class="gydy-left">
                <div class="l-head"></div> <a href="/xinwenzhongxin/"
                    class="block pr list tc f16 text-black a-hover active">新闻中心</a>
                <a href="/xinpinfabu/" class="block pr list tc f16 text-black a-hover ">行业动态</a>
                <a href="/lianxiwomen/" class="block pr list tc f16 text-black a-hover ">联系我们</a>
            </div>
            <div class="gydy-right clearfix">
                <div class="c-a love mb20 clearfix">
                    <a class="block img lazy zoomBox fl" target="_blank" href="/yyanldetail/">
                        <img class="lazy_img zoom" src="../assets/static/upload/image/20221104/1667549986168527.png" alt="">
                    </a>
                    <div class="content fr">
                        <h2 class="ellipsis">
                            <a target="_blank" href="/xinwenzhongxin/357.html"
                                class="text-black f18 a-hover">同骋工匠会员招募火热进行中，快来薅羊毛啦！</a>
                        </h2>
                        <p class="ellipsis4 describe mt20">
                            <a class="text-sub f14 a-hover" target="_blank"
                                href="/xinwenzhongxin/357.html">同骋工匠会员招募开始啦，加入同骋工匠会员，即可享受多种会员福利，如:加入会员后“同骋工匠平台”小程序家装家政服务9折起，无门槛优惠礼券免费赠送等。同骋天下会员福利多多，快来薅羊毛啦！...</a>
                        </p>
                        <p class="text-color9 f14 ellipsis mt20">发布时间：2022-11-04</p>
                    </div>
                </div>
                <div class="c-a love mb20 clearfix">
                    <a class="block img lazy zoomBox fl" target="_blank" href="/xinwenzhongxin/356.html">
                        <img class="lazy_img zoom" src="../assets/static/upload/image/20221028/1666946779685203.jpg" alt="">
                    </a>
                    <div class="content fr">
                        <h2 class="ellipsis">
                            <a target="_blank" href="/xinwenzhongxin/356.html"
                                class="text-black f18 a-hover">家电清洗招商加盟流程有哪些？</a>
                        </h2>
                        <p class="ellipsis4 describe mt20">
                            <a class="text-sub f14 a-hover" target="_blank"
                                href="/xinwenzhongxin/356.html">同骋天下，一站式家电清洁综合运营管理全国连锁平台，公司专注于家居环保项目的研发与经营。旗下“同骋天下”专注家电健康服务，有专业、环保、高
                                效的家电服务技术团队为人类家居健康保驾护航。同骋天下采用独特的品牌扶持运营模式,始终致力为人类提供较新较好的绿色环保产品，推动中国健康生活产业的发展。...</a>
                        </p>
                        <p class="text-color9 f14 ellipsis mt20">发布时间：2022-10-28</p>
                    </div>
                </div>
                <div class="c-a love mb20 clearfix">
                    <a class="block img lazy zoomBox fl" target="_blank" href="/xinwenzhongxin/355.html">
                        <img class="lazy_img zoom" src="../assets/static/upload/image/20221028/1666946011184966.png" alt="">
                    </a>
                    <div class="content fr">
                        <h2 class="ellipsis">
                            <a target="_blank" href="/xinwenzhongxin/355.html"
                                class="text-black f18 a-hover">家电清洗招商加盟靠谱吗？</a>
                        </h2>
                        <p class="ellipsis4 describe mt20">
                            <a class="text-sub f14 a-hover" target="_blank"
                                href="/xinwenzhongxin/355.html">疲惫下班之后还要面对一堆的家务，让人更加压抑。为了让生活更加轻松，很多人会选择家政服务，这样既节省了时间，又处理了家务，达到了双赢场面。同骋天下家电清洗招加盟品牌影响力不容小觑，在市场上也有较高的人气。...</a>
                        </p>
                        <p class="text-color9 f14 ellipsis mt20">发布时间：2022-10-28</p>
                    </div>
                </div>
                <div class="c-a love mb20 clearfix">
                    <a class="block img lazy zoomBox fl" target="_blank" href="/xinwenzhongxin/354.html">
                        <img class="lazy_img zoom" src="../assets/static/upload/image/20221028/1666945614140641.png" alt="">
                    </a>
                    <div class="content fr">
                        <h2 class="ellipsis">
                            <a target="_blank" href="/xinwenzhongxin/354.html"
                                class="text-black f18 a-hover">家电清洗招商加盟的优势有哪些？</a>
                        </h2>
                        <p class="ellipsis4 describe mt20">
                            <a class="text-sub f14 a-hover" target="_blank"
                                href="/xinwenzhongxin/354.html">电器长时间使用之后，就会成为污渍的聚居地，而且这些污渍长时间残留会对我们的生活健康造成影响，所以定期进行家电清洗是非常有必要的。同骋天下，一个装修、装饰、家政、维修、清洗等一站式综合服务用工平台，让用户不再多花冤枉钱，让师傅天天有活干。同骋天下品牌凭借创新互联网+经营模式，专业技术操作，以及人性化服务理念，开启家电清洗4.0时代，引领环保健康新生活。...</a>
                        </p>
                        <p class="text-color9 f14 ellipsis mt20">发布时间：2022-10-28</p>
                    </div>
                </div>
                <div style="clear: both;"></div>
                <div class="pagess">
                    <ul> <span class='page-status'>共18条 当前1/5页</span><span class='page-index'><a
                                href='/xinwenzhongxin/'>首页</a></span><span class='page-pre'><a
                                href='javascript:;'>前一页</a></span><span class='page-numbar'><a href="/xinwenzhongxin/"
                                class="page-num page-num-current">1</a><a href="/xinwenzhongxin_2/" class="page-num">2</a><a
                                href="/xinwenzhongxin_3/" class="page-num">3</a><a href="/xinwenzhongxin_4/"
                                class="page-num">4</a><a href="/xinwenzhongxin_5/" class="page-num">5</a></span><span
                            class='page-next'><a href='/xinwenzhongxin_2/'>后一页</a></span><span class='page-last'><a
                                href='/xinwenzhongxin_5/'>尾页</a></span> </ul>
            </div>
        </div>
    </div>
    <div class="list-detail container clearfix" style="width:800px">
        <div class="left-box" data-fixed="position">
            <Rementj></Rementj>
        </div>
    </div>
    <footerbox></footerbox>
</div></template>
<script>
$(window).ready(function() {

$(window).scroll(function() {

    var pos = $(window).scrollTop();

    var bottomBox = $(".head-sub").find('.bottomBox');

    if (pos > 500) {

        $('.zyA_right').show();

    } else {

        $('.zyA_right').hide();

    }

    if (pos > 110) {

        bottomBox.addClass('fixedX');

    } else {

        bottomBox.removeClass('fixedX');

    }

});

$('.case-ban li').click(function() {

    var n = $(this).index();

    var t = $('.i-main .sign').eq(n).offset().top;

    $('body,html').animate({

        'scrollTop': t - 100

    });

})

$('.anli_pro .content a').wrap("<span></span>");

// $('.anli_pro .content b').hide();

var swiper = new Swiper('.swiper-container', {

    slidesPerView: 3,

    spaceBetween: 30,

    navigation: {

        nextEl: '.swiper-button-next',

        prevEl: '.swiper-button-prev',

    },



});

})
$(function() {

$(document).scroll(function() {

    var scroH = $(document).scrollTop(); //滚动高度



    if (scroH > 400) {

        $('#headerhome').css("position", "fixed");

    } else {

        $('#headerhome').css("position", "inherit");

    }

    if (scroH < 550) {
        $('.construction_right').css("position", "static");
    } else if (scroH >= 550) {
        $('.construction_right').css({
            "position": "sticky",
            "top": "15%",
            "left": "66%"
        });

    }


})

$(".menu li").mouseover(function() {

    $(this).css({

        "color": "#FF6602"

    }).siblings().css({

        "color": "#101920"

    });

})

$(".menu li").mouseout(function() {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
$('.domesticOutfit .doys span').click(function() {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
// $('.position-relative').hover(function() {

//     $(this).find('.hnav-second').stop().slideToggle("500");

//     $(this).find('.hnav').stop().slideToggle("500");

// });
$("#footer .share a").hover(function(e) {
    $(this).find("figure").fadeToggle('fast');
});

var urlstr = location.href; //获取浏览器的url

var urlstatus = false; //标记

$("#headerhome .menu li>a").each(function() {

    //判断导航里面的rel和url地址是否相等

    if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

        $(this).find('.io').addClass('ms');

        $(this).css("color", "#FF6602");

        urlstatus = true;

    } else {

        $(this).find('.io').removeClass('ms');

        $(this).css("color", "#101920");

    }

});

//当前样式保持

if (!urlstatus) {

    $("#menu a").eq(0).addClass('cur');

}



$('b span:last').after('</br>');

})
$(function() {

$(".mobile").hover(function() {

    $(this).find(".mobilecode").css({

        "display": "block"

    })

}, function() {

    $(this).find(".mobilecode").css({

        "display": "none"

    })

})

$(".side ul li").hover(function() {

    $(this).find(".sidebox").stop().css({

        "opacity": "1",

        "filter": "Alpha(opacity=100)",

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "block"

    })



}, function() {

    $(this).find(".sidebox").stop().animate({

        "width": "54px"

    }, 200).css({

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "none"

    })

});

$(window).scroll(function() {

    if ($(window).scrollTop() > 100) {

        $("#back-to-top").fadeIn(300);

    } else {

        $("#back-to-top").fadeOut(300);

    }

});

});
import Rementj from '@/components/rementj.vue';
import footerbox from '../components/footerbox.vue'
export default {
    name: 'xinwenzhongxin',
    components: {
        footerbox,
        Rementj
    },
    data() {
        return {
        }
    },


}
</script>