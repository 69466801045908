<template>
  <div id="Home">
    <header>

      <div class="container f-cb header-top por">

        <h1>

          <a href="/" title="同骋天下（北京）科技有限公司"><img src="../assets/uploads/image/20220103/1641176780694271.png"
              alt="同骋天下（北京）科技有限公司"></a>

        </h1>

        <div class="link fr">

          <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">

            <div class="yousan tianmao">

              <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>

              <img class="wx-img" src="../assets/images/xiaocx.jpg" />

            </div>

            <div class="wx yousan" id="wechat">

              <a href="#"><img src="../assets/images/wx1.png" width="28"></a>

              <img class="wx-img" src="../assets/images/weixin.jpg" />

            </div>

            <div class="phone yousan wx" id="wechat">

              <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>

              <img class="wx-img" src="../assets/images/sjh.png">

            </div>

          </div>

          <!-- <a class="tmall" href="/cpzx/" title="产品中心" target="_blank" rel="nofollow">&nbsp;&nbsp;</a> -->

          <!-- <a class="language" href="/" title="EN" target="_blank">13353433133</a> -->

        </div>

      </div>

      <div class="header-bot 1">

        <div class="container f-cb">

          <div class="nav fl">

            <ul>



              <li>

                <a class="z1" href="/cpzx/" title="产品中心">产品中心</a>

                <div class="header-slide">

                  <dl class="container">



                    <dd>

                      <a href="/xiazaizhongxin/" title=""><img
                          src="../assets/uploads/210610/1-21061009345L11.jpg" width="134" height="121" alt="下载中心">

                        <p>下载中心</p>

                      </a>

                    </dd>



                    <dd>

                      <a href="/cpzx/" title="产品介绍-家具养护招商加盟-家具贴膜招商加盟-开荒保洁招商加盟-同骋工匠（同聘工匠）"><img
                          src="../assets/uploads/210610/1-210610093513333.jpg" width="134" height="121" alt="产品介绍">

                        <p>产品介绍</p>

                      </a>

                    </dd>



                  </dl>

                </div>

              </li>



              <li>

                <a class="z1" href="/yingyonganli/" title="工程案例">工程案例</a>

                <div class="header-slide">

                  <dl class="container">



                  </dl>

                </div>

              </li>



              <li>

                <a class="z1" href="/pinpaijieshao/" title="品牌介绍">品牌介绍</a>

                <div class="header-slide">

                  <dl class="container">



                    <dd>

                      <a href="/pinpaijieshao/" title=""><img
                          src="../assets/uploads/210610/1-210610093942O6.jpg" width="134" height="121" alt="关于我们">

                        <p>关于我们</p>

                      </a>

                    </dd>



                    <dd>

                      <a href="/fazhanlicheng/" title=""><img
                          src="../assets/uploads/210610/1-21061009400JL.jpg" width="134" height="121" alt="发展历程">

                        <p>发展历程</p>

                      </a>

                    </dd>



                    <dd>

                      <a href="/guanyuwomen/" title=""><img
                          src="../assets/uploads/210610/1-21061009402B58.jpg" width="134" height="121" alt="品牌介绍">

                        <p>品牌介绍</p>

                      </a>

                    </dd>



                    <dd>

                      <a href="/lianxiwomen/" title=""><img
                          src="../assets/uploads/210610/1-2106100940543K.jpg" width="134" height="121" alt="联系我们">

                        <p>联系我们</p>

                      </a>

                    </dd>



                  </dl>

                </div>

              </li>



              <li>

                <a class="z1" href="/cizhuanbaike/" title="品牌百科">品牌百科</a>

                <div class="header-slide">

                  <dl class="container">



                  </dl>

                </div>

              </li>



              <li>

                <a class="z1" href="/video/" title="服务视频">服务视频</a>

                <div class="header-slide">

                  <dl class="container">



                  </dl>

                </div>

              </li>



              <li>

                <a class="z1" href="/meitidongtai/" title="媒体动态">媒体动态</a>

                <div class="header-slide">

                  <dl class="container">



                    <dd>

                      <a href="/xinwenzhongxin/" title="新闻中心-家装招商加盟-油烟机安装招商加盟-开荒保洁招商加盟-同骋工匠（同聘工匠）"><img
                          src="../assets/uploads/210610/1-2106100944234D.jpg" width="134" height="121" alt="新闻中心">

                        <p>新闻中心</p>

                      </a>

                    </dd>



                    <dd>

                      <a href="/xinpinfabu/" title="行业新闻-瓷砖美缝招商加盟-家政服务招商加盟-门锁安装招商加盟-同骋工匠（同聘工匠）"><img
                          src="../assets/uploads/210610/1-210610094439244.jpg" width="134" height="121" alt="行业动态">

                        <p>行业动态</p>

                      </a>

                    </dd>



                  </dl>

                </div>

              </li>



              <li>

                <a class="z1" href="/zhaoshangjiameng/" title="招商加盟">招商加盟</a>

                <div class="header-slide">

                  <dl class="container">



                    <dd>

                      <a href="/zhaoshangjiameng/" title=""><img
                          src="../assets/uploads/210610/1-2106100942494b.jpg" width="134" height="121" alt="加盟合作">

                        <p>加盟合作</p>

                      </a>

                    </dd>



                    <dd>

                      <a href="/hezuohuoban/" title=""><img
                          src="../assets/uploads/210610/1-210610094325H8.jpg" width="134" height="121" alt="合作伙伴">

                        <p>合作伙伴</p>

                      </a>

                    </dd>



                  </dl>

                </div>

              </li>



            </ul>

          </div>

          <a class="found-btn" href="javascript:;" title=""></a>

        </div>

      </div>

    </header>

    <div id="header" style="display: none;margin-top: 0;">

      <div class="header-middle">

        <div class="con">

          <div class="yz-row header_inner">

            <div class="yz-column whb-visible-lg">

              <div class="header-logo">

                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                    src="../assets/images/logos.png" alt="同城工匠"></a>

              </div>

            </div>

            <div class="yz-column zhong whb-visible-lg">

              <div class="con text-right">

                <ul class="menu">

                  <li class="position-relative" style="color: #FF6602;"><a href="/"><span>首页</span><span
                        class="ms"></span></a></li>



                  <li class="position-relative">

                    <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io" style="left: 27px;"></span></a>

                    <div class="hnav-second">



                      <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>

                      <a href="/cpzx/" class="a-hover hlist">产品介绍</a>

                    </div>

                  </li>



                  <li class="position-relative">

                    <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                        style="left: 27px;"></span></a>

                    <div class="hnav-second">



                    </div>

                  </li>



                  <li class="position-relative">

                    <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                        style="left: 27px;"></span></a>

                    <div class="hnav-second">



                      <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>

                    </div>

                  </li>



                  <li class="position-relative">

                    <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                        style="left: 27px;"></span></a>

                    <div class="hnav-second">



                    </div>

                  </li>



                  <li class="position-relative">

                    <a href="/video/" rel="/video/"><span>服务视频</span><span class="io" style="left: 27px;"></span></a>

                    <div class="hnav-second">



                    </div>

                  </li>



                  <li class="position-relative">

                    <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                        style="left: 27px;"></span></a>

                    <div class="hnav-second">



                      <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>

                      <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>

                    </div>

                  </li>



                  <li class="position-relative">

                    <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span class="io"
                        style="left: 27px;"></span></a>

                    <div class="hnav-second">



                      <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>

                      <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>

                    </div>

                  </li>



                </ul>

              </div>

            </div>

            <div class="yz-column whb-visible-lg" style="margin-left: 20px;display: flex;">

              <div class="yousan tianmao">

                <a href="javascript:;"><img src="../assets/images/xiaocxu2.png" alt=""></a>

                <img class="wx-img mall" src="../assets/images/xiaocx.jpg" alt="" style="" />

              </div>

              <div class="wx yousan" id="wechat">

                <a href="#"><img src="../assets/images/wx1.png" alt="" width="28"></a>

                <img class="wx-img" src="../assets/images/weixin.jpg" alt="" style="" />

              </div>

              <div class="phone yousan wx">

                <a href="tel:13353433133" title=""><img src="../assets/images/phione.png" alt="" width="28"></a>

                <img class="wx-img" src="../assets/images/sjh.png">

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

    <!-- banner图片 -->

    <div id="playBox" class="play-box" style="position: relative;">

      <!--图片列表-->

      <div id="imgList">



        <a href="javascript:;">

          <img src="../assets/uploads/image/20221028/1666942521515852.png" />

        </a>



        <a href="javascript:;">

          <img src="../assets/uploads/image/20220103/1641175909458978.png" />

        </a>



        <a href="javascript:;">

          <img src="../assets/uploads/image/20221028/1666942532141558.png" />

        </a>



      </div>

      <!--图标列表-->

      <div class="iconList">

        <ul>

          <li class="current">1</li>

          <li>2</li>

          <li>3</li>

        </ul>

      </div>

      <!--左箭头-->

      <div class="sliderbar slidebar-left">

        <span class="iconfont" style="font-size: 37px;"></span>

      </div>

      <!--右箭头-->

      <div class="sliderbar slidebar-right"><span class="iconfont" style="font-size: 37px;"></span></div>

    </div>

    <div class="container">

      <div class="indexTitle">

        <h2 class="title">关于我们</h2>

        <div class="subTitle"> 同骋工匠（同聘工匠），是一个全国连锁的综合型师傅用工平台

        </div>

        <div class="lne">

          <div class="dot"></div>

        </div>

      </div>

      <div class="index-about">

        <div class="row">

          <div class="col-xs-12 col-md-7  d-flex justify-content-center mr-auto">

            <div class=" align-self-center flex-column">

              <span style="color: rgba(0, 0, 0, 1);display: block;font-size: 24px;font-weight: 600">全球领先的工匠师傅</span>

              <p class="dt" style="text-indent: 2em;">

                同骋工匠，全国连锁家装师傅用工平台，是同骋天下（北京）科技有限公司旗下的核心项目之一，公司成立于2020年10月30日，公司总部坐落于首都北京市。

                平台致力于搭建一个商户、师傅与业主间的无障碍桥梁，整合同城师傅资源，提升行业效率，建造一个家装、家政一站式综合服务平台。平台倡导工匠精神，用工匠标准要求自己，用工匠精神服务他人


              <p style="text-indent: 2em;line-height: 24px;">公司使命：整合家装师傅，提升行业效率</p><br>

              <p style="color:#ff6602!important;text-align: right;"> <a href="/yingyonganli/shangye/">更多关于我们 ＞</a></p>

              </p>

            </div>

          </div>

          <div class="col-xs-12 col-md-5 text-right">

            <div class="lb2">

              <div class="swiper-container swiper-container-initialized swiper-container-horizontal" id="lb2">

                <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">

                  <div class="swiper-slide swiper-no-swiping swiper-slide-active" style="width: 344px;">

                    <a href="/yingyonganli/shangye/">

                      <img class="img-fluid" src="../assets/images/qgzp.jpg" alt="" srcset="">

                    </a>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

    <div class="box1">

      <div class="container over">

        <div class="w1">

          <a href="/" target="_blank">

            <div class="box animationBox mr10 inline">

              <div class="tit ellipsis">

                <h2 class="iconfont"></h2>

              </div>

              <p class="ellipsis3-auto">

                <span class="ellipsis3-auto-t">2年</span><br>

                <span class="ellipsis3-auto-b">多年的平台经验</span>

              </p>

            </div>

            <div class="box animationBox mr10 inline">

              <div class="tit ellipsis">

                <h2 class="iconfont"></h2>

              </div>

              <p class="ellipsis3-auto">

                <span class="ellipsis3-auto-t">100+</span><br>

                <span class="ellipsis3-auto-b">100+全球客户</span>

              </p>

            </div>

            <div class="box animationBox mr10 inline">

              <div class="tit ellipsis">

                <h2 class="iconfont"></h2>

              </div>

              <p class="ellipsis3-auto">

                <span class="ellipsis3-auto-t">2个</span><br>

                <span class="ellipsis3-auto-b">2个环保型工厂</span><br>

                <span class="ellipsis3-auto-b">3个全球设计中心</span>

              </p>

            </div>

            <div class="box animationBox mr10 inline">

              <div class="tit ellipsis">

                <h2 class="iconfont"></h2>

              </div>

              <p class="ellipsis3-auto">

                <span class="ellipsis3-auto-t">308+</span><br>

                <span class="ellipsis3-auto-b">308+全国网点</span>

              </p>

            </div>

            <div class="box animationBox inline active">

              <div class="tit ellipsis">

                <h2 class="iconfont"></h2>

              </div>

              <p class="ellipsis3-auto">

                <span class="ellipsis3-auto-t">100+</span><br>

                <span class="ellipsis3-auto-b">100+安装师傅</span>

              </p>

            </div>

            <div style="clear: both;"></div>

          </a>

        </div>

      </div>

    </div>

    <div class="box2">

      <div class="indexTitle">

        <h2 class="title">产品中心</h2>

        <div class="subTitle">选择同骋工匠（同聘工匠） 更是选择对品质的追求</div>

        <div class="lne">

          <div class="dot"></div>

        </div>

      </div>

      <div class="container">

        <ul class="clearfix">

          <li style="width:236px" class="mr5 pr GioEvent" GioEventId='trafficClick'
            GioEventVars="area_var=甄选服务产品&trafficname_var=别墅大宅&position_var=1">

            <a href="/cpzx/" target="_blank" class="lazy" rel="nofollow">

              <img src="../assets/images/zns_01.jpg" class="lazy_img zoom auto" alt="精英全案">

            </a>

            <div class="con">

              <div class="tit">智能锁</div>

              <div class="subTit">快乐指尖，锁定秘密</div>

              <div class="des">智能门锁是指区别于传统机械锁的基础上改进的，在用户安全性、识别、管理性方面更加智能化简便化的锁具。</div>

              <a href="/cpzx/" target="_blank" class="button button-primary is-plain" rel="nofollow">查看详情</a>

            </div>

          </li>

          <li style="width:236px" class="mr5 pr GioEvent" GioEventId='trafficClick'
            GioEventVars="area_var=甄选服务产品&trafficname_var=全案设计&position_var=2">

            <a href="/cpzx/" target="_blank" class="lazy zoomBox" rel="nofollow">

              <img src="../assets/images/bhaz_01.png" class="lazy_img zoom auto" alt="全案设计">

            </a>

            <div class="con">

              <div class="tit">壁画安装</div>

              <div class="subTit">自然灵感，温情之美</div>

              <div class="des">设计以简约为主，崇尚时尚主义；源自大自然的灵感，纹理精致细腻，光泽柔和自然，处处尽显温情。</div>

              <a href="/cpzx/" target="_blank" class="button button-primary is-plain" rel="nofollow">查看详情</a>

            </div>

          </li>

          <li style="width:236px" class="mr5 pr GioEvent" GioEventId='trafficClick'
            GioEventVars="area_var=甄选服务产品&trafficname_var=品质整装&position_var=3">

            <a href="/cpzx/" target="_blank" rel="nofollow" class="lazy zoomBox">

              <img src="../assets/images/ds_01.jpg" class="lazy_img zoom auto" alt="整装套餐">

            </a>

            <div class="con">

              <div class="tit">灯饰安装</div>

              <div class="subTit">灵动美妙，艺术之美</div>

              <div class="des">不规则的变化的灯光有着不可预见的美妙，呈现时尚灵动的经典之光。</div>

              <a href="/cpzx/" target="_blank" rel="nofollow" class="button button-primary is-plain">查看详情</a>

            </div>

          </li>

          <li style="width:236px" class="mr5 pr GioEvent" GioEventId='trafficClick'
            GioEventVars="area_var=甄选服务产品&trafficname_var=全屋软装&position_var=4">

            <a href="/cpzx/" target="_blank" class="lazy zoomBox" rel="nofollow">

              <img src="../assets/images/jjyh_01.jpg" class="lazy_img zoom auto" alt="软装配饰">

            </a>

            <div class="con">

              <div class="tit">家居养护</div>

              <div class="subTit">超凡脱俗，点睛之美</div>

              <div class="des">错落有致的纹路，灵动的线条，营造出温馨气氛，富有层次感。</div>

              <a href="/cpzx/" target="_blank" class="button button-primary is-plain" rel="nofollow">查看详情</a>

            </div>

          </li>

          <li style="width:236px" class="pr GioEvent" GioEventId='trafficClick'
            GioEventVars="area_var=甄选服务产品&trafficname_var=老房&position_var=5">

            <a href="/cpzx/" target="_blank" class="lazy zoomBox" rel="nofollow">

              <img src="../assets/images/sctm_01.jpg" class="lazy_img zoom auto" alt="老房">

            </a>

            <div class="con">

              <div class="tit">石材贴膜</div>

              <div class="subTit">沉稳大气，生活之美</div>

              <div class="des">柔和雅致的色系，多变的纹理，营造大空间的舒适自然，装饰出高品位的审美与格调。</div>

              <a href="/cpzx/" target="_blank" class="button button-primary is-plain" rel="nofollow">查看详情</a>

            </div>

          </li>

        </ul>

        <div class="tc mt60">

          <a target="_blank" href="/cpzx/" class="button button-primary f18 GioEvent"
            style="width:200px;letter-spacing:2px;">更多产品</a>

        </div>

      </div>

    </div>

    <div class="box3 bg-bg">

      <div class="indexTitle">

        <div>

          <h2 class="title">工程案例</h2>

        </div>

        <div class="subTitle">10余种潮流风格，数万套精品案例作品，设计师精心点评，让您快速找到灵感</div>

        <div class="lne">

          <div class="dot"></div>

        </div>

        <div class="tc tag">



        </div>

      </div>

      <div class="container over">

        <div class="img" style="width: 1213px;padding-left:0;">



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/qt/395.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670408493114835.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/qt/395.html" target="_blank">家装设计</a> | <a
                href="/yingyonganli/qt/395.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/qt/395.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/qt/394.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670408412133562.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/qt/394.html" target="_blank">家庭维修</a> | <a
                href="/yingyonganli/qt/394.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/qt/394.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/qt/393.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670408344414807.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/qt/393.html" target="_blank">验房</a> | <a
                href="/yingyonganli/qt/393.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/qt/393.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/qt/392.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670408215182610.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/qt/392.html" target="_blank">甲醛检测</a> | <a
                href="/yingyonganli/qt/392.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/qt/392.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/qt/391.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670408162616439.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/qt/391.html" target="_blank">甲醛治理</a> | <a
                href="/yingyonganli/qt/391.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/qt/391.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/az/390.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670408078954877.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/az/390.html" target="_blank">开关灯具安装</a> | <a
                href="/yingyonganli/az/390.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/az/390.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/az/389.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670407989211503.jpg" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/az/389.html" target="_blank">晾衣架安装</a> | <a
                href="/yingyonganli/az/389.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/az/389.html"
                target="_blank"></a>

            </span>

          </div>



          <div class="imgBox lazy_img zoomBox">

            <a target="_blank" class="lazy full GioEvent" href="/yingyonganli/az/388.html" rel="nofollow">

              <img src="../assets/uploads/image/20221207/1670407924101172.png" class="lazy_img zoom" alt="">

            </a>

            <span class="f14 tag"><a href="/yingyonganli/az/388.html" target="_blank">卫浴安装</a> | <a
                href="/yingyonganli/az/388.html" target="_blank">超级管理员</a> | <a href="/yingyonganli/az/388.html"
                target="_blank"></a>

            </span>

          </div>



        </div>

        <div class="tc mt60">

          <a target="_blank" href="/yingyonganli/" class="button button-primary f18 GioEvent" style="width:150px"
            rel="nofollow">更多案例</a>

        </div>

      </div>

    </div>

    <div class="box8 container">

      <div class="indexTitle">

        <h2 class="title">全国50城 热装楼盘免费参观</h2>

        <div class="subTitle">去您家所在的楼盘，实地参观我们的工地，专业施工、良心品质，我们欢迎您莅临考察</div>

        <div class="lne">

          <div class="dot"></div>

        </div>

      </div>

      <div class="city-list lazy_img pr" :style="{ 'background-image': 'url(' + bgImage + ')' }">

        <a href="#" target="_blank" style="left:0;top:0" class="GioEvent">

          <span class="c">北京市</span>

        </a>

        <a href="#" target="_blank" style="left:309px;top:0" class="GioEvent">

          <span class="c">上海市</span>

        </a>

        <a href="#" target="_blank" style="left:619px;top:0" class="GioEvent">

          <span class="c">深圳市</span>

        </a>

        <a href="#" target="_blank" style="left:928px;top:0" class="GioEvent">

          <span class="c">杭州市</span>

        </a>

        <a href="#" target="_blank" style="left:155px;top:171px" class="GioEvent">

          <span class="c">南京市</span>

        </a>

        <a href="#" target="_blank" style="left:464px;top:171px" class="GioEvent">

          <span class="c">武汉市</span>

        </a>

        <a href="#" target="_blank" style="left:775px;top:171px" class="GioEvent">

          <span class="c">成都市</span>

        </a>

        <a href="#" target="_blank" style="left:0;top:344px" class="GioEvent">

          <span class="c">西安市</span>

        </a>

        <a href="#" target="_blank" style="left:309px;top:344px" class="GioEvent">

          <span class="c">郑州市</span>

        </a>

        <a href=".#" target="_blank" style="left:621px;top:344px" class="GioEvent">

          <span class="c">青岛市</span>

        </a>

        <a href="#" target="_blank" style="left:929px;top:329px" class="GioEvent">

          <span class="iconfont"></span>

          <span class="c">查看更多</span>

        </a>

      </div>

    </div>

    <div class="box12">

      <div class="indexTitle">

        <h2 class="title">新闻资讯</h2>

        <div class="subTitle">海量攻略，让您深入了解的各个环节，给自己的家更多想象空间，发现更多灵感</div>

        <div class="lne">

          <div class="dot"></div>

        </div>

      </div>

      <div class="container">

        <div class="titles">

          <!-- {pboot:sort scode=24} -->

          <div class="right mt35 mb10">

            <a href="/yingyonganli/" target="_blank" rel="nofollow">

              查看全部 >

            </a>

          </div>



        </div>

        <div class="news-list mt40">

          <div class="left">



            <div class="img-box lazy">

              <a href="/xinwenzhongxin/357.html" target="_blank" rel="nofollow">

                <img src="../assets/uploads/image/20221104/1667549986168527.png" class="lazy_img" width="100%"
                  alt="同骋工匠会员招募火热进行中，快来薅羊毛啦！" title="同骋工匠会员招募火热进行中，快来薅羊毛啦！">

              </a>

            </div>

            <a target="_blank" href="/xinwenzhongxin/357.html" class="title">

              同骋工匠会员招募火热进行中，快来薅羊毛啦！

            </a>

            <div class="article-time"></div>

            <div class="sub-title"> 同骋工匠会员招募开始啦，加入同骋工匠会员，即可享受多种会员福利，如:加入会员后“同···</div>



          </div>

          <div class="right">

            <ul class="new-tabs">



              <a target="_blank" href="/xinpinfabu/360.html" class="GioEvent" GioEventId='trafficClick'
                GioEventVars="area_var=资讯&areaColumns_var=头条文章&trafficname_var=装潢公司房屋有什么流程?同城工匠超放心带你全面了解">

                <li>

                  <div class="time">

                    <span class="month">11</span>

                    <span class="date">/04</span>

                  </div>

                  <div class="content">

                    <div class="title">家具家电招商加盟的条件有哪些？</div>

                    <div class="sub-title">俗话说得好“男怕入错行，女怕嫁错郎”，想要创业致富，项目选择是关键。同骋工匠，是一个全国连锁的综合型···...</div>

                  </div>

                </li>

              </a>



              <a target="_blank" href="/xinpinfabu/359.html" class="GioEvent" GioEventId='trafficClick'
                GioEventVars="area_var=资讯&areaColumns_var=头条文章&trafficname_var=装潢公司房屋有什么流程?同城工匠超放心带你全面了解">

                <li>

                  <div class="time">

                    <span class="month">11</span>

                    <span class="date">/04</span>

                  </div>

                  <div class="content">

                    <div class="title">家具家电招商加盟的流程有哪些？</div>

                    <div class="sub-title">随着当今社会的快速的快速发展，人们的生活水平有了明显的提高，生活家具家电的使用已经成为人们的生活中必···...</div>

                  </div>

                </li>

              </a>



              <a target="_blank" href="/xinpinfabu/358.html" class="GioEvent" GioEventId='trafficClick'
                GioEventVars="area_var=资讯&areaColumns_var=头条文章&trafficname_var=装潢公司房屋有什么流程?同城工匠超放心带你全面了解">

                <li>

                  <div class="time">

                    <span class="month">11</span>

                    <span class="date">/04</span>

                  </div>

                  <div class="content">

                    <div class="title">家电清洗招商加盟该如何选址？</div>

                    <div class="sub-title">家务清洁占据了我们生活和工作之外的大多数时间，这让人着实很头疼。现在家电清洗公司可以解决人们的这一烦···...</div>

                  </div>

                </li>

              </a>



              <a target="_blank" href="/xinpinfabu/345.html" class="GioEvent" GioEventId='trafficClick'
                GioEventVars="area_var=资讯&areaColumns_var=头条文章&trafficname_var=装潢公司房屋有什么流程?同城工匠超放心带你全面了解">

                <li>

                  <div class="time">

                    <span class="month">09</span>

                    <span class="date">/09</span>

                  </div>

                  <div class="content">

                    <div class="title">美缝瓷砖的注意事项有哪些？</div>

                    <div class="sub-title">1、美缝剂的质量，一定不能太差在选择美缝剂上面，一定不要买质量太差的。如果美缝剂的质量太差的话，不仅视···...</div>

                  </div>

                </li>

              </a>



            </ul>

          </div>

        </div>

      </div>

    </div>

    <!-- 底部JS -->

  <footerbox></footerbox>
  </div>
</template>
  
<script>
import footerbox from '../components/footerbox.vue'

$(window).ready(function () {

  $(window).scroll(function () {

    var pos = $(window).scrollTop();

    var bottomBox = $(".head-sub").find('.bottomBox');

    if (pos > 500) {

      $('.zyA_right').show();

    } else {

      $('.zyA_right').hide();

    }

    if (pos > 110) {

      bottomBox.addClass('fixedX');

    } else {

      bottomBox.removeClass('fixedX');

    }

  });

})
$(function () {
  $(document).scroll(function () {

    var scroH = $(document).scrollTop(); //滚动高度

    if (scroH > 400) {

      $('#header').show();

    } else {

      $('#header').hide();

    }

  })

  $(".menu li").mouseover(function () {

    $(this).css({

      "color": "#FF6602"

    }).siblings().css({

      "color": "#101920"

    });

  })

  $(".menu li").mouseout(function () {

    $(".menu li").eq(0).css({

      "color": "#FF6602"

    }).siblings().css({

      "color": "#101920"

    });

  })

  // 滚动事件

  $('.ku').click(function () {

    $('#rightxuanfu').hide();

  })

  $("#footer .share a").hover(function (e) {

    $(this).find("figure").fadeToggle('fast');

  });

  $('.position-relative').hover(function () {

    $(this).find('.hnav-second').stop().slideToggle("500");

    $(this).find('.hnav').stop().slideToggle("500");

  });
  // ========
  $(".mobile").hover(function () {

    $(this).find(".mobilecode").css({

      "display": "block"

    })

  }, function () {

    $(this).find(".mobilecode").css({

      "display": "none"

    })

  })

  $(".side ul li").hover(function () {

    $(this).find(".sidebox").stop().css({

      "opacity": "1",

      "filter": "Alpha(opacity=100)",

      "background": "#FF6602"

    })

    $(this).find(".weixin").css({

      "display": "block"

    })

  }, function () {

    $(this).find(".sidebox").stop().animate({

      "width": "54px"

    }, 200).css({

      "background": "#FF6602"

    })

    $(this).find(".weixin").css({

      "display": "none"

    })

  });

  $('.header-bot .nav li').hover(function () {

    $(this).find('.header-slide').stop(true, true).fadeIn(0);

  }, function () {

    $(this).find('.header-slide').stop(true, true).fadeOut(0);

  })
})
// =====
var urlstr = location.href; //获取浏览器的url

var urlstatus = false; //标记

$("#header .menu li>a").each(function () {

  //判断导航里面的rel和url地址是否相等

  if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

    $(this).find('.io').addClass('ms');

    urlstatus = true;

  } else {

    $(this).find('.io').removeClass('ms');

  }

});

//当前样式保持

if (!urlstatus) {

  $("#menu a").eq(0).addClass('cur');

}
export default {
  name: 'Home',
   components: {
    footerbox
  },
  data() {
    return {
      bgImage: require('../assets/images/index_0830_03.jpg')
    }
  },


}
</script>
<style></style>
  