<template>
    <div class="remencp">
        <div class="container">
            <div class="title-sjsal mt30 mb10">
                <h2>{{ title }}</h2>
                <div class="more"><a href="/cpzx/" target="" class="a-hover" rel="nofollow">查看更多 <i
                            class="iconfont icon-gengduo1"></i></a></div>
            </div>
        </div>
        <div class="container sjslb">
            <div class="content-cards">
                <div class="zhuangxiu-box" v-for="item,index in list" :key="index">
                    <div class="zhuangxiu-card zx1">
                        <div class="img-box lazy load-over">
                            <a :href="'/cpzxdetail?id='+item.id" rel="nofollow">
                                <img class="lazy_img auto load-over"
                                    :src="item.image" alt="" title=""
                                    rel="nofollow"
                                    style="display: block;  height: 100%;left: 30.99647%; top: 0px;z-index: 1;">
                            </a>
                            <div class="img-box-xy"><img :src="item.image2"
                                    alt="" height="213"></div>
                        </div>
                        <div class="detail-box">
                            <div class="msg">
                                <div class="title">
                                    <a :href="'/cpzxdetail?id='+item.id">{{ item.name }}</a>
                                </div>
                                <div class="sub">
                                    <a class="a-hover" :href="'/cpzxdetail?id='+item.id">{{ item.pinlei }}</a> | <a
                                        class="a-hover" :href="'/cpzxdetail?id='+item.id">{{ item.xilie }}</a> | <a
                                        class="a-hover" :href="'/cpzxdetail?id='+item.id">{{ item.yanse }}</a>
                                </div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>
                </div>
              
                <div class="cl"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'remencp',
    props: {
        title: {
            type:String,
            default:'热门产品'
        }
    },
    data() {
        return {list:[]}
    },
    mounted() {
        this.$api.common.productHot().then(res => {
            console.log(res)
            this.list = res.data.result

        }).catch(res => {

        })
    }
}
</script>


