<template>
    <div class="cpzx">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
            <div class="header-middle">
                <div class="con">
                    <div class="yz-row header_inner">
                        <div class="yz-column whb-visible-lg">
                            <div class="header-logo">
                                <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid"
                                        src="../assets/images/logos.png" alt="同骋天下"></a>
                            </div>

                        </div>
                        <div class="yz-column zhong whb-visible-lg">
                            <div class="con text-right">
                                <ul class="menu">
                                    <li class="position-relative"><a href="/"><span>首页</span></a></li>

                                    <li class="position-relative">
                                        <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a>
                                            <a href="/cpzx/" class="a-hover hlist">产品介绍</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/video/" rel="/video/"><span>服务视频</span><span class="io"
                                                style="left: 27px;"></span></a>

                                    </li>

                                    <li class="position-relative">
                                        <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io"
                                                style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a>
                                            <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a>
                                        </div>
                                    </li>

                                    <li class="position-relative">
                                        <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span
                                                class="io" style="left: 27px;"></span></a>

                                        <div class="hnav-second">

                                            <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                            <div class="yousan tianmao">
                                <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                            </div>
                            <div class="wx yousan" id="wechat">
                                <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/weixin.jpg" />
                            </div>
                            <div class="phone yousan wx" id="wechat">
                                <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" style="width: 100%;">
            <img src="../assets/images/product.jpg" alt="产品中心" width="100%">
        </div>
        <div class="container mianbao" style="position: relative;">
            <div class="anli_nav"> 当前位置 ： <a href="/">首页</a>>><a href="/cpzx/">产品中心</a> </div>
            <div class="clearfix" id="anliyun">
            </div>
            <div class="cl"></div>
        </div>
        <div class="container">
            <div class="select-box">
                <div class="select-card">
                    <ul class="list-box" id="list-box" data-listbox="">
                        <li class="anli_pro" style="clear:both;">
                            <div class="title"></div>
                            <div class="content">
                                <span class="active">全部</span>
                                <span>热搜系列</span>
                                <!-- <a href="/cpzx/" class="">全部</a>  
                        <a href="/cpzx/?ext_rmxl=%E9%A9%AC%E6%A1%B6%E5%AE%89%E8%A3%85" >马桶安装</a> |
                        <a href="/cpzx/?ext_rmxl=%E7%81%B6%E5%8F%B0%E8%B4%B4%E8%86%9C" >灶台贴膜</a> |
                        <a href="/cpzx/?ext_rmxl=%E7%93%B7%E7%A0%96%E7%BE%8E%E7%BC%9D" >瓷砖美缝</a> |
                        <a href="/cpzx/?ext_rmxl=%E9%A9%AC%E6%A1%B6%E7%96%8F%E9%80%9A" >马桶疏通</a> |
                        <a href="/cpzx/?ext_rmxl=%E5%BC%80%E8%8D%92%E4%BF%9D%E6%B4%81" >开荒保洁</a> <br> -->
                                <span>装修系列</span>
                                <!-- <a href="/cpzx/" class="">全部</a>  
                        <a href="/cpzx/?ext_price=%E9%A9%AC%E6%A1%B6%E5%AE%89%E8%A3%85" >马桶安装</a> |
                        <a href="/cpzx/?ext_price=%E6%B5%B4%E5%AE%A4%E6%9F%9C%E5%AE%89%E8%A3%85" >浴室柜安装</a> |
                        <a href="/cpzx/?ext_price=%E7%AA%97%E5%B8%98%E5%AE%89%E8%A3%85" >窗帘安装</a> |
                        <a href="/cpzx/?ext_price=%E5%A3%81%E7%BA%B8%E9%93%BA%E8%B4%B4" >壁纸铺贴</a> |
                        <a href="/cpzx/?ext_price=%E7%81%B6%E5%8F%B0%E8%B4%B4%E8%86%9C" >灶台贴膜</a> <br> -->
                                <span>家具系列</span>
                                <!-- <a href="/cpzx/" class="">全部</a> 
                        <a href="/cpzx/?ext_type=%E7%81%AF%E9%A5%B0%E5%AE%89%E8%A3%85" >灯饰安装</a> |
                        <a href="/cpzx/?ext_type=%E6%8A%BD%E6%B2%B9%E7%83%9F%E6%9C%BA%E5%AE%89%E8%A3%85" >抽油烟机安装</a> |
                        <a href="/cpzx/?ext_type=%E5%A3%81%E7%94%BB%E5%AE%89%E8%A3%85" >壁画安装</a> |
                        <a href="/cpzx/?ext_type=%E6%99%BA%E8%83%BD%E9%94%81" >智能锁</a> |
                        <a href="/cpzx/?ext_type=%E6%B4%97%E8%A1%A3%E6%9C%BA%E5%AE%89%E8%A3%85" >洗衣机安装</a> |
                        <a href="/cpzx/?ext_type=%E6%8A%95%E5%BD%B1%E4%BB%AA%E5%AE%89%E8%A3%85" >投影仪安装</a> <br> -->
                                <span>清洁系列</span>
                                <!-- <a href="/cpzx/" class="">全部</a> 
                        <a href="/cpzx/?ext_color=%E5%BC%80%E8%8D%92%E4%BF%9D%E6%B4%81" >开荒保洁</a> |
                        <a href="/cpzx/?ext_color=%E9%80%9F%E7%AB%8B%E6%B4%81%E6%B2%BB%E7%90%86" >速立洁治理</a> |
                        <a href="/cpzx/?ext_color=%E6%B2%B9%E7%83%9F%E6%9C%BA%E6%B8%85%E6%B4%97" >油烟机清洗</a> |
                        <a href="/cpzx/?ext_color=%E7%96%8F%E9%80%9A%E6%B0%B4%E7%AE%A1" >疏通水管</a> |
                        <a href="/cpzx/?ext_color=%E7%8E%BB%E7%92%83%E6%B8%85%E6%B4%81" >玻璃清洁</a> |
                        <a href="/cpzx/?ext_color=%E5%86%B0%E7%AE%B1%E6%B8%85%E6%B4%97" >冰箱清洗</a> -->
                            </div>
                            <div class="cl"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container pro" style="width: 1200px; position: relative;">
            <div class="row">
                <div class="probox" v-for="item, index in list" :key="index">
                    <div class="col-12">
                        <div class="module-039">
                            <a :href="'/cpzxdetail?id='+item.id">
                                <div class="img-box">
                                    <!-- 产品图 -->
                                    <div class="over-img">
                                        <div class="xysimg tp xysimgQT">
                                            <img :src="item.image2" style="max-height: 100%; width: auto;">
                                        </div>
                                    </div>
                                    <!-- 产品图 end -->
                                    <!-- 场景图 -->
                                    <div class="over-img tpcover">
                                        <div class="xysimg tp">
                                            <img :src="item.image" style="max-height: 100%; width: auto;">
                                        </div>
                                    </div>
                                    <!-- 场景图 end -->
                                </div>
                                <div class="text-box" style="text-align: center">
                                    <strong class="title">{{ item.name }}</strong>
                                    <!-- <p class="classification">ss古砖</p> -->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <div style="clear: both;"></div>
        </div>
        <div class="pagess" style="padding-top: 0;">
            <ul> <span class='page-status'>共{{total}}条 当前{{page}}/{{total}}页</span>
                <span class='page-index'><a @click="getList(1)">首页</a></span>
                <span class='page-pre'><a @click="getList(page - 1)">前一页</a></span>
                <span class='page-numbar' v-for="item,index in Array.from({length: page0}, (val, i) => i)" :key="index">
                    <a @click="getList(item+1)" class="page-num page-num-current">{{ item+1 }}</a>
                </span>
                <span class='page-next'><a @click="getList(page+1)">后一页</a></span><span class='page-last'><a
                    @click="getList(page0)">尾页</a></span>
            </ul>
        </div>
        <remencp></remencp>
        <footerbox></footerbox>
    </div>
</template>
<script>
import footerbox from '../components/footerbox.vue'
import remencp from '../components/remencp.vue'

$(function () {
    let arr = [];
    $(".content").find("span").click(function () {
        $(".content").find("span").removeClass("active");
        $(this).addClass("active");
        if ($(this)[0].innerText == "热搜系列") {
            arr = ["智能马桶安装", "灶台贴膜", "玻璃清洁", "疏通水管", "深度保洁"]
        } else if ($(this)[0].innerText == "装修系列") {
            arr = ["智能马桶安装", "浴室柜安装", "窗帘安装", "壁纸铺贴", "灶台贴膜"]
        } else if ($(this)[0].innerText == "家具系列") {
            arr = ["灯饰安装", "抽油烟机安装", "壁画安装", "智能锁", "洗衣机安装", "投影仪安装"]
        } else if ($(this)[0].innerText == "清洁系列") {
            arr = ["深度保洁", "速立洁治理", "油烟机清洗", "疏通水管", "玻璃清洁", "冰箱清洗"]
        } else {
            arr = []
        }

        $($(".text-box").find(".title")).each(function (index, domEle) {
            if (!arr[0]) {
                domEle.closest(".probox").style.display = 'block'
                return
            }
            domEle.closest(".probox").style.display = 'none'
            for (var i = 0; i < arr.length; i++) {
                if (domEle.innerText == arr[i]) {
                    domEle.closest(".probox").style.display = 'block'
                }
            }
        })
    });
    // ============
    $(document).scroll(function () {

        var scroH = $(document).scrollTop(); //滚动高度



        if (scroH > 400) {

            $('#headerhome').css("position", "fixed");

        } else {

            $('#headerhome').css("position", "inherit");


        }

        if (scroH < 550) {
            $('.construction_right').css("position", "static");
        } else if (scroH >= 550) {
            $('.construction_right').css({
                "position": "sticky",
                "top": "15%",
                "left": "66%"
            });

        }


    })

    $(".menu li").mouseover(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })

    $(".menu li").mouseout(function () {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
    $('.domesticOutfit .doys span').click(function () {
        $('.domesticOutfit').hide();
    })
    // // 滚动事件
    // $('.position-relative').hover(function () {

    //     $(this).find('.hnav-second').stop().slideToggle("500");

    //     $(this).find('.hnav').stop().slideToggle("500");

    // });
    $("#footer .share a").hover(function (e) {
        $(this).find("figure").fadeToggle('fast');
    });

    var urlstr = location.href; //获取浏览器的url

    var urlstatus = false; //标记

    $("#headerhome .menu li>a").each(function () {

        //判断导航里面的rel和url地址是否相等

        if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

            $(this).find('.io').addClass('ms');

            $(this).css("color", "#FF6602");

            urlstatus = true;

        } else {

            $(this).find('.io').removeClass('ms');

            $(this).css("color", "#101920");

        }

    });

    //当前样式保持

    if (!urlstatus) {

        $("#menu a").eq(0).addClass('cur');

    }



    $('b span:last').after('</br>');

    // ========
    $(".mobile").hover(function () {

        $(this).find(".mobilecode").css({

            "display": "block"

        })

    }, function () {

        $(this).find(".mobilecode").css({

            "display": "none"

        })

    })

    $(".side ul li").hover(function () {

        $(this).find(".sidebox").stop().css({

            "opacity": "1",

            "filter": "Alpha(opacity=100)",

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "block"

        })



    }, function () {

        $(this).find(".sidebox").stop().animate({

            "width": "54px"

        }, 200).css({

            "background": "#FF6602"

        })

        $(this).find(".weixin").css({

            "display": "none"

        })

    });

    $(window).scroll(function () {

        if ($(window).scrollTop() > 100) {

            $("#back-to-top").fadeIn(300);

        } else {

            $("#back-to-top").fadeOut(300);

        }

    });

})
export default {
    name: 'cpzx',
    components: {
        footerbox, remencp
    },
    data() {
        return {
            list: [],
            page: 1,
            size: 6,
            total:0,
            page0:0
        }
    },
    mounted() {
        this.getList(1)
    },
    methods: {
        getList(page) {
            if(page<=0){
                page=1
            }
            this.page = page
            this.list = []
            this.$api.common.productList({ page: this.page, size: this.size }).then(res => {
                console.log(res)
                this.total = res.data.result.total
                this.list = res.data.result.list
                this.page0 = parseInt(this.total/this.size)+1

            }).catch(res => {

            })

        },
    }


}
</script>
<style>
.active {
    color: #ff6602;
}

.content a {
    display: inline-block;
    padding: 2px 8px;
    margin-left: 10px;
    margin-top: 13px;
    line-height: 1.5;
    height: 20px;
}
</style>