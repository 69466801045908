import axios from '@/utils/request' // axios 实例引入（第五节封装的）

const common = {
  productList: (params) => axios({
    url: '/product/index',
    method: 'get',
    params
  }),
  productDetail: (params) => axios({
    url: '/product/detail',
    method: 'get',
    params
  }),
  productHot: (params) => axios({
    url: '/product/hot',
    method: 'get',
    params
  }) ,
  videoList: (params) => axios({
    url: '/video/index',
    method: 'get',
    params
  }),
  engineering: (params) => axios({
    url: '/engineering/index',
    method: 'get',
    params
  }),
  engineeringDetail: (params) => axios({
    url: '/engineering/detail',
    method: 'get',
    params
  }),
  
  newsList: (params) => axios({
    url: '/news/index',
    method: 'get',
    params
  }),
  newsDetail: (params) => axios({
    url: '/news/detail',
    method: 'get',
    params
  }),
  newshot: (params) => axios({
    url: '/news/hot',
    method: 'get',
    params
  }),
  contact: (data) => axios({
    url: '/contact/index',
    method: 'post',
    data
  }),
  join: (data) => axios({
    url: '/join/index',
    method: 'post',
    data
  }),
}

export default common
