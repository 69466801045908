<template>
    <div class="xiazaizhongxin">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
        <div class="header-middle">
            <div class="con">
                <div class="yz-row header_inner">
                    <div class="yz-column whb-visible-lg">
                        <div class="header-logo">
                            <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid" 
							src="../assets/images/logos.png" alt="同骋天下"></a>
                        </div>

                    </div>
                    <div class="yz-column zhong whb-visible-lg">
                        <div class="con text-right">
                            <ul class="menu">
                                <li class="position-relative"><a href="/"><span>首页</span></a></li>
                                
                                <li class="position-relative">
                                    <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a> 
                                        <a href="/cpzx/" class="a-hover hlist">产品介绍</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/video/" rel="/video/"><span>服务视频</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a> 
                                        <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/zhaoshangjiameng/" class="a-hover hlist">加盟合作</a>
                                            <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                        <div class="yousan tianmao">
                            <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                        </div>
                        <div class="wx yousan" id="wechat">
                            <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/weixin.jpg" />
                        </div>
                        <div class="phone yousan wx" id="wechat">
                           <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="width: 100%;">
    <img src="../assets/images/ceramic.jpg" alt="" width="100%">
</div>
<div class="container mianbao">
    <div class="anli_nav">
        当前位置 ： <a href="/">首页</a>>><a href="/cpzx/">产品中心</a>>><a href="/xiazaizhongxin/">下载中心</a>
    </div>
    <div class="clearfix" id="anliyun">
    </div>
    <div class="cl"></div>
</div>

<div class="container">
    <div class="select-box">
        <div class="select-card">
            <ul class="list-box" id="list-box" data-listBox>
                <li class="anli_pro" style="clear:both;">
                    <div class="title">下载中心</div>
                    <div class="content" style="margin-left: 90px;">
                        <span :class="{active:active=='1'}" @click="active=1"><a>全部</a></span> 
                        <span :class="{active:active=='2'}" @click="active=2"><a >产品贴图</a></span> 
                        <span :class="{active:active=='3'}" @click="active=3"><a >检测报告</a></span> 
                        <span :class="{active:active=='4'}" @click="active=4"><a >公司资质</a></span> 
                        <div class="cl"></div>
                    </div>

                    <div class="cl"></div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="container sjslb mt20">
    <div class="content-cards">
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179584480901.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="智能锁">智能锁</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179584480901.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179584480901.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179156636100.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="壁画安装">壁画安装</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179156636100.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179156636100.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179245349752.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="壁纸铺贴">壁纸铺贴</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179245349752.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179245349752.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179292449567.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="冰箱清洗">冰箱清洗</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179292449567.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179292449567.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179336219487.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="窗帘安装">窗帘安装</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179336219487.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179336219487.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179417793806.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="玻璃清洁">玻璃清洁</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179417793806.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179417793806.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179460601159.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="抽油烟机安装">抽油烟机安装</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179460601159.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179460601159.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        
        <div class="zhuangxiu-box">
            <div class="zhuangxiu-card">
                <div class="img-box">
                    <a class="GioEvent" GioEventId="formClick">
                    </a>
                    <a href="javascript:;" title="" rel="nofollow">
                        <img class="lazy_img auto" src="../assets/static/upload/image/20220103/1641179511692037.jpg" alt="" title="">
                    </a>
                </div>
                <div class="detail-box">
                    <div class="msg" style="margin-left: 10px;text-align: left;">
                        <div class="title">
                            <a class="text-color4" href="javascript:;" title="灯饰安装">灯饰安装</a>
                        </div>
                        <div class="sub text-color9">
                            <a class="a-hover" href="javascript:;">2021-03-30</a>
                            <a href="../assets/static/upload/image/20220103/1641179511692037.jpg" style="float: right;margin-right: 20px;" class="a-hover" download="../assets/static/upload/image/20220103/1641179511692037.jpg">下载>></a>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
        </div>
        

        <div class="cl"></div>
    </div>
</div>
        <footerbox></footerbox>
    </div>
</template>
<script>
 $(window).ready(function() {
        $(window).scroll(function() {
            var pos = $(window).scrollTop();
            var bottomBox = $(".head-sub ").find('.bottomBox');
            if (pos > 500) {
                $('.zyA_right').show();
            } else {
                $('.zyA_right').hide();
            }
            if (pos > 110) {
                bottomBox.addClass('fixedX');
            } else {
                bottomBox.removeClass('fixedX');
            }
        });
    }) 
    // $(function(){
    //  let res1 =  $(".content span a")[0].pathname
    //  let res2 =  $(".content span a")[1].pathname
    //  let res3 =  $(".content span a")[2].pathname
    //  let res4 =  $(".content span a")[3].pathname
    // //  console.log("res",res1,res2,res3,res4);
    //   // let test =window.location.href;
    //   let test = window.location.href.replace("https://tongchenggj.com",'') //只会替换掉第一个,且原目标不变,需要重新赋值给一个变量}
    //  //如果你想把所有的都替换掉，要使用正则	例: "bob".replace(/b/g, 'l') === 'lol'
    // // console.log("window.location.href",test);
    // $(".content span").removeClass("active");
    // if(test == res1){
    //   $(".content span")[0].className = 'active'
    // }else if(test == res2){
    //   $(".content span")[1].className = 'active'
    // }else if(test == res3){
    //   $(".content span")[2].className = 'active'
    // }else if(test == res4){
    //   $(".content span")[3].className = 'active'
    // }
    // })
    $(function() {
        $(".mobile ").hover(function() {
            $(this).find(".mobilecode ").css({
                "display ": "block "
            })
        }, function() {
            $(this).find(".mobilecode ").css({
                "display ": "none "
            })
        })
        $(window).scroll(function() {
            if ($(window).scrollTop() > 100) {
                $("#back-to-top ").fadeIn(300);
            } else {
                $("#back-to-top ").fadeOut(300);
            }
        });
    });
    //回到顶部函数
    function goTop() {
        $('html,body').animate({
            'scrollTop': 0
        }, 600);
    }
import footerbox from '../components/footerbox.vue'
export default {
    name: 'xiazaizhongxin',
    components: {
        footerbox
    },
    data() {
        return {
            active:1
        }
    },


}
</script>
<style>
    .sjslb .content-cards .zhuangxiu-box .zhuangxiu-card {
        height: auto;
    }
    
    .sjslb .content-cards .zhuangxiu-box .zhuangxiu-card .img-box {
        height: auto;
    }
    
    .select-box .select-card .list-box li .content {
        padding-bottom: 0;
    }
</style>