<template>
    <div class="zhaoshangjiameng">
        <div id="headerhome" style="margin-top: 0;position: inherit;">
        <div class="header-middle">
            <div class="con">
                <div class="yz-row header_inner">
                    <div class="yz-column whb-visible-lg">
                        <div class="header-logo">
                            <a href="/" class="d-inline-block" style="width:100%"><img class=" img-fluid" 
							src="../assets/images/logos.png" alt="同骋天下"></a>
                        </div>

                    </div>
                    <div class="yz-column zhong whb-visible-lg">
                        <div class="con text-right">
                            <ul class="menu">
                                <li class="position-relative"><a href="/"><span>首页</span></a></li>
                                
                                <li class="position-relative">
                                    <a href="/cpzx/" rel="/cpzx/"><span>产品中心</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xiazaizhongxin/" class="a-hover hlist">下载中心</a> 
                                        <a href="/cpzx/" class="a-hover hlist">产品介绍</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/yingyonganli/" rel="/yingyonganli/"><span>工程案例</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/pinpaijieshao/" rel="/pinpaijieshao/"><span>品牌介绍</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/pinpaijieshao/" class="a-hover hlist">关于我们</a>
                                            <a href="/fazhanlicheng/" class="a-hover hlist">发展历程</a>
                                            <a href="/guanyuwomen/" class="a-hover hlist">品牌介绍</a>
                                            <a href="/lianxiwomen/" class="a-hover hlist">联系我们</a>
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/cizhuanbaike/" rel="/cizhuanbaike/"><span>品牌百科</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/video/" rel="/video/"><span>服务视频</span><span class="io" style="left: 27px;"></span></a>
                                    
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/meitidongtai/" rel="/meitidongtai/"><span>媒体动态</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/xinwenzhongxin/" class="a-hover hlist">新闻中心</a> 
                                        <a href="/xinpinfabu/" class="a-hover hlist">行业动态</a> 
                                    </div>
                                </li>
                                
                                <li class="position-relative">
                                    <a href="/zhaoshangjiameng/" rel="/zhaoshangjiameng/"><span>招商加盟</span><span class="io" style="left: 27px;"></span></a>
                                    
                                    <div class="hnav-second">
                                        
                                        <a href="/jiamenghezuo/" class="a-hover hlist">加盟合作</a> 
                                        <a href="/hezuohuoban/" class="a-hover hlist">合作伙伴</a> 
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="yz-column whb-visible-lg" style="margin-left: 20px;z-index: 99;display: flex;">
                        <div class="yousan tianmao">
                            <a href="#"><img src="../assets/images/xiaocxu.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/xiaocx.jpg" />
                        </div>
                        <div class="wx yousan" id="wechat">
                            <a href="#"><img src="../assets/images/wx1.png" width="28"></a>
                            <img class="wx-img" src="../assets/images/weixin.jpg" />
                        </div>
                        <div class="phone yousan wx" id="wechat">
                           <!-- <a href="tel:13353433133" data-toggle="tooltip" data-placement="bottom">
								<img src="../assets/images/phione.png" width="25"></a> -->
                                <a href="tel:13353433133"><img src="../assets/images/phione.png" width="28"></a>
                                <img class="wx-img" src="../assets/images/sjh.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="container" style="width: 100%;">
    <img src="../assets/images/cooperation.jpg" alt="" width="100%">
</div>
<div class="container mianbao">
    <div class="anli_nav">
        当前位置 ： <a href="/">首页</a>>><a href="/zhaoshangjiameng/">招商加盟</a>>><a href="/zhaoshangjiameng/">加盟合作</a>
    </div>
    <div class="clearfix" id="anliyun">
    </div>
    <div class="cl"></div>
</div>
<div class="container-list">

    <ul class="container">

        
        <li class="aon"><a href="/zhaoshangjiameng/">加盟合作</a></li> 
        <li><a href="/hezuohuoban/">合作伙伴</a></li>

    </ul>

</div>
<div class="brand_1 container" style="margin-top: 30px;">
    <div class="page_title">
        <h4>加盟条件</h4>
        <p>Condition</p>
    </div>
    <p class="join-3con">认同同骋天下定位及目标；认同同骋天下定位及目标；具有强烈的合作意愿和信心，良好的商业信誉、职业道德和社会关系； 有一定的代理运转资金，具备市场经营管理能力；具有品牌运作理念，有一定的市场推广和销售技巧；有稳定的运作团队，良好的区域 市场开拓和管理能力。
    </p>
    <ul class="clear">
        <li>
            <div><span>经销商选择要求</span><span><a href="/contact/">加盟申请</a></span></div>
            <div style="margin-left:45px;">
                <p><b>城市级别：</b>省会城市</p>
                <p><b>面积：</b>N&gt;80平方</p>
                <p><b>流动资金：</b>50万</p>
            </div>
        </li>
        <li>
            <div><span>终端店面要求</span><span><a href="/contact/">加盟申请</a></span></div>
            <div style="margin-left:45px;">
                <p><b>城市级别：</b>地级城市</p>
                <p><b>面积：</b>N&gt;50平方</p>
                <p><b>流动资金：</b>30万</p>
            </div>
        </li>
        <li>
            <div><span>经销要求</span><span><a href="/contact/">加盟申请</a></span></div>
            <div style="margin-left:45px;">
                <p><b>城市级别：</b>地级市以下地方</p>
                <p><b>面积：</b>200平方&gt;N&gt;20平方</p>
                <p><b>流动资金：</b>10万</p>
            </div>
        </li>
    </ul>

</div>
<div class="brand_1 container" style="margin-top: 30px;">
    <div class="page_title">
        <h4>加盟流程</h4>
        <p>Process</p>
    </div>
    <img src="../assets/images/join-4.jpg" width="100%">
</div>
<div class="brand_1 container" style="margin-top: 30px;">
    <div class="page_title">
        <h4>加盟申请</h4>
        <p>Application</p>
    </div>
    <div class="main container">
        <div class="form" >
            <div class="f-cb">
                <input type="text" class="name line1" name="name" v-model="form.name" placeholder="姓名：">
                <input type="text" class="phone line1" name="phone" v-model="form.phone" placeholder="联系电话：">
                <input type="text" class="email line1" name="email" v-model="form.email" placeholder="邮箱：">
            </div>
            <textarea name="remark" id="content" placeholder="在线留言：" v-model="form.remark"></textarea>
            <div class="verify">
                <input type="submit" value="提交申请" class="submit" @click="submitPost">
            </div>
        </div>
    </div>
</div>
        <footerbox></footerbox>
    </div>
</template>
<script>
   $(window).ready(function() {

$(window).scroll(function() {

    var pos = $(window).scrollTop();

    var bottomBox = $(".head-sub").find('.bottomBox');

    if (pos > 500) {

        $('.zyA_right').show();

    } else {

        $('.zyA_right').hide();

    }

    if (pos > 110) {

        bottomBox.addClass('fixedX');

    } else {

        bottomBox.removeClass('fixedX');

    }

});

$('.case-ban li').click(function() {

    var n = $(this).index();

    var t = $('.i-main .sign').eq(n).offset().top;

    $('body,html').animate({

        'scrollTop': t - 100

    });

})

$('.anli_pro .content a').wrap("<span></span>");

// $('.anli_pro .content b').hide();

var swiper = new Swiper('.swiper-container', {

    slidesPerView: 3,

    spaceBetween: 30,

    navigation: {

        nextEl: '.swiper-button-next',

        prevEl: '.swiper-button-prev',

    },



});

})
$(function() {

$(document).scroll(function() {

    var scroH = $(document).scrollTop(); //滚动高度



    if (scroH > 400) {

        $('#headerhome').css("position", "fixed");

    } else {

        $('#headerhome').css("position", "inherit");

    }

    if (scroH < 550) {
        $('.construction_right').css("position", "static");
    } else if (scroH >= 550) {
        $('.construction_right').css({
            "position": "sticky",
            "top": "15%",
            "left": "66%"
        });

    }


})

$(".menu li").mouseover(function() {

    $(this).css({

        "color": "#FF6602"

    }).siblings().css({

        "color": "#101920"

    });

})

$(".menu li").mouseout(function() {

        $(this).css({

            "color": "#FF6602"

        }).siblings().css({

            "color": "#101920"

        });

    })
    // 点击删除
$('.domesticOutfit .doys span').click(function() {
        $('.domesticOutfit').hide();
    })
    // 滚动事件
// $('.position-relative').hover(function() {

//     $(this).find('.hnav-second').stop().slideToggle("500");

//     $(this).find('.hnav').stop().slideToggle("500");

// });
$("#footer .share a").hover(function(e) {
    $(this).find("figure").fadeToggle('fast');
});

var urlstr = location.href; //获取浏览器的url

var urlstatus = false; //标记

$("#headerhome .menu li>a").each(function() {

    //判断导航里面的rel和url地址是否相等

    if ((urlstr + '/').indexOf($(this).attr('rel')) > -1 && $(this).attr('rel') != '') {

        $(this).find('.io').addClass('ms');

        $(this).css("color", "#FF6602");

        urlstatus = true;

    } else {

        $(this).find('.io').removeClass('ms');

        $(this).css("color", "#101920");

    }

});

//当前样式保持

if (!urlstatus) {

    $("#menu a").eq(0).addClass('cur');

}



$('b span:last').after('</br>');

})
$(function() {

$(".mobile").hover(function() {

    $(this).find(".mobilecode").css({

        "display": "block"

    })

}, function() {

    $(this).find(".mobilecode").css({

        "display": "none"

    })

})

$(".side ul li").hover(function() {

    $(this).find(".sidebox").stop().css({

        "opacity": "1",

        "filter": "Alpha(opacity=100)",

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "block"

    })



}, function() {

    $(this).find(".sidebox").stop().animate({

        "width": "54px"

    }, 200).css({

        "background": "#FF6602"

    })

    $(this).find(".weixin").css({

        "display": "none"

    })

});

$(window).scroll(function() {

    if ($(window).scrollTop() > 100) {

        $("#back-to-top").fadeIn(300);

    } else {

        $("#back-to-top").fadeOut(300);

    }

});

});
import footerbox from '../components/footerbox.vue'
export default {
    name: 'zhaoshangjiameng',
    components: {
        footerbox
    },
    data() {
        return {
            form:{}
        }
    },
    methods:{
        submitPost(){
            console.log(this.form)
            this.$api.common.join(this.form).then(res => {

        }).catch(res => {

        })
        }
    }


}
</script>