<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
$(function () {
  // 点击目录 字体变色
  $('.sous').click(function (e) {
    $('.serh').show();
    $('.serh').click(function (e) {
      $(this).show();
      e.stopPropagation();
    })
    $(document).one("click", function () {
      $('.serh').hide();
    })
    e.stopPropagation();
  })

  $('.popupUl .popuLi').click(function () {
    $(this).addClass('popupActive').siblings().removeClass('popupActive');

    $(this).find(".popupList").fadeIn().siblings().fadeOut();
  })
  $('.wx').hover(function () {
    $(this).find('.wx-img').toggle();
  })
 
  $(function () {
    $('.falseImg').click(function () {
      window.location.href = "/";
    })
    $('.nav-fangda').click(function () {
      $('.searchInput').show();
      $('.nav-box').hide();
    })
    var lis = $('.searchResult .probox').length;
    $('#num').html(lis);
  })


})
export default {
  name: 'App',

}
</script>

<style>
#app {}
</style>
